<template>
  <div class="mt-1 rolling-container">
    <router-link
      to="/board/notice"
      class="text-decoration-none"
      :class="{
        rolling: rolling,
      }"
      style="
        color: #212121;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      "
    >
      {{
        (notices[count % notices.length]?.subject ?? "") +
        `[${formatDatetimeMinute(notices[count % notices.length]?.crtDt)}]`
      }}
      <v-tooltip location="bottom" activator="parent">
        <div
          v-dompurify-html="
            notices[count % notices.length]?.noticeComment ?? ''
          "
        />
      </v-tooltip>
    </router-link>
  </div>
  <template v-for="popup in noticePopups" :key="popup.idx ?? '0'">
    <NoticeViewDialog
      popup
      :model-value="popup"
      :title="t('board.notice.notice')"
      @click:download-file="(val) => onClickDownloadFile(val)"
      @click:cancel="onClickCancel"
    />
  </template>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { type Notice } from "@/views/board/notice/models";
import { downloadFileByUrl, getApi } from "@/utils/apis";
import {
  promiseTimeout,
  refAutoReset,
  useCounter,
  useIntervalFn,
} from "@vueuse/core";
import { formatDatetimeMinute } from "@/utils/formatter";
import NoticeViewDialog from "@/views/board/notice/NoticeViewDialog.vue";
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/config";
import { storeToRefs } from "pinia";

const { t } = useI18n();

const animationDuration = ref(2_000);
const noticeInterval = ref(10_000);

const notices = ref<Notice[]>([]);
const rolling = refAutoReset(false, animationDuration.value + 100);

async function fetchNotice() {
  const { success, data } = await getApi<Notice[]>(`api/v1/notices/rolling/`);
  if (success) {
    notices.value = data;
  }
}

const { pause } = useIntervalFn(fetchNotice, 1_000 * 60 * 5);
const { inc, count } = useCounter();
const { pause: pauseCycle } = useIntervalFn(inc, noticeInterval.value);

watch(count, async () => {
  await promiseTimeout(noticeInterval.value - animationDuration.value);
  rolling.value = true;
});

const { noticePopups } = storeToRefs(useConfigStore());

function onClickDownloadFile(val: Notice) {
  if (val.attachments?.[0]?.fileUri) {
    downloadFileByUrl(
      val.attachments?.[0]?.fileUri,
      val.attachments?.[0]?.fileTitle ?? val.attachments?.[0]?.fileName,
    );
  }
}

function onClickCancel(val: Notice) {
  noticePopups.value = noticePopups.value.filter((v) => v !== val);
}

onBeforeMount(async () => {
  await fetchNotice();
  await promiseTimeout(2_000);
});
onBeforeUnmount(() => {
  pause();
  pauseCycle();
});
</script>

<style scoped>
.rolling-container {
  position: relative;
  height: 30px; /* Adjust height accordingly */
  overflow: hidden;
}
.rolling {
  height: 30px; /* Ensure this is the same as .notice-container */
  display: flex;
  align-items: center;
  animation: marquee 2s linear;
}

@keyframes marquee {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
