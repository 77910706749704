<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn icon size="36" v-bind="props">
        <v-avatar size="36" color="rgb(var(--v-theme-secondary))">
          <v-icon size="24" color="white">mdi-account</v-icon>
        </v-avatar>
      </v-btn>
      <div
        style="
          color: #212121;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 2px;
          margin-left: 8px;
        "
        class="mr-4"
      >
        {{ user.cusUsrName }}
      </div>
    </template>

    <v-list class="py-0" style="right: -12px">
      <v-list-item
        prepend-icon="mdi-restore-alert"
        :title="t('button.fetchCode')"
        @click="fetchCode"
      />
      <v-list-item
        prepend-icon="mdi-logout-variant"
        :title="t('button.logout')"
        @click="signOut"
      />
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { signOut } from "@/utils/commands";
import { useUserStore } from "@/stores/user";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toast-notification";
import { fetchFullData } from "@/utils/codes";

const { t } = useI18n();

const { user } = useUserStore();

const toast = useToast();
function fetchCode() {
  fetchFullData();
  toast.success(t("button.fetchCode"));
}
</script>
