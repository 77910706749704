<template>
  <div>
    <div v-if="editorRef" class="editor">
      <TextEditorMenuBar
        v-if="!disabled"
        class="menu"
        :editor="
          // @ts-ignore
          editorRef
        "
      />
      <editor-content :editor="editorRef" />
    </div>
    <v-text-field
      v-show="!disabled"
      ref="refValidMessage"
      v-model="validMessage"
      class="ma-0 pa-0"
      style="width: 0; height: 0"
      validate-on="input"
      :clearable="false"
      :rules="[required ? requiredRule : true]"
    />
  </div>
</template>

<script lang="ts" setup>
import { EditorContent, useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { computed, nextTick, ref, watch } from "vue";
import { tryOnBeforeUnmount, watchDebounced } from "@vueuse/core";
import Image from "@tiptap/extension-image";
import { Underline } from "@tiptap/extension-underline";
import TextEditorMenuBar from "@/views/components/texteditor/TextEditorMenuBar.vue";
import { required as requiredRule } from "@/utils/rules";
import i18n from "@/plugins/vue-i18n";

const modelValue = defineModel<string>({ required: true });
const props = withDefaults(
  defineProps<{
    label?: string;
    disabled?: boolean;
    required?: boolean;
  }>(),
  {
    label: i18n.global.t("common.content"),
  },
);

const validMessage = ref(modelValue.value);

const refValidMessage = ref();
const borderColor = ref("rgba(var(--v-theme-black), 0.21)");
const editorRef = useEditor({
  content: modelValue.value,
  extensions: [StarterKit, Image, Underline],
  onUpdate: async ({ editor }) => {
    // HTML
    modelValue.value = editor.getHTML() ?? "";

    validMessage.value = editor.isEmpty ? "" : "-";
    await nextTick();
    const result = await refValidMessage.value.validate();
    borderColor.value =
      result.length > 0
        ? "rgb(var(--v-theme-error))"
        : "rgba(var(--v-theme-black), 0.21)";
  },
  editable: !props.disabled,
});

const paddingTop = computed(() => (props.disabled ? "12px" : "56px"));

watchDebounced(
  modelValue,
  async (val) => {
    const isSame = editorRef.value?.getHTML() === val;
    if (isSame) {
      return;
    }

    editorRef.value?.commands.setContent(val, false);
  },
  { debounce: 500 },
);

watch(
  () => props.disabled,
  (newVal) => {
    editorRef.value?.setOptions({ editable: !newVal });
  },
);

const height = computed(() => (props.disabled ? "auto" : "300px"));

tryOnBeforeUnmount(() => {
  editorRef.value?.destroy();
});
</script>
<style lang="scss">
.editor {
  position: relative;
  width: 100%;
}

.menu {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 2px;

  width: calc(100% - 4px);
  height: 40px;
  margin: 0;
  padding: 0 8px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid rgba(var(--v-theme-black), 0.21);
  background-color: rgb(var(--v-theme-surface));
  color: darkgray;
}

.link-menu {
  display: flex;
  align-items: center;

  /* height: 40px; */
  padding: 8px;

  border: 1px solid rgba(var(--v-theme-black), 0.21);
  border-radius: 4px;
  background-color: rgba(var(--v-theme-black), 0.05);
}

/* Basic editor styles */
.ProseMirror {
  font-size: 13px;
  min-height: 100px;
  height: v-bind(height);
  overflow: scroll;
  padding: v-bind(paddingTop) 12px 12px;

  border: 1px solid v-bind(borderColor);
  border-radius: 4px;

  > * + * {
    margin-top: 0.5rem;
  }

  p {
    margin-bottom: 0;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  a {
    color: rgb(var(--v-theme-primary));
  }

  kbd,
  code {
    padding: 4px;

    border-radius: 4px;
    background-color: rgba(var(--v-theme-black), 0.05);
    color: rgb(var(--v-theme-secondary));

    box-decoration-break: clone;

    font-size: 14px;
  }
}

.ProseMirror-focused {
  border: 1px solid v-bind(borderColor);
  outline: none;
}
</style>

<style lang="scss" scoped>
::v-deep(.v-input) {
  > .v-input__control {
    height: 0;
  }
}
</style>
