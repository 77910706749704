export default {
  ko: {
    myInfo: {
      tabs: {
        customer: "회사정보",
        price: "Price",
        api: "API",
        waybill: "운송장출력양식",
        favorite: "즐겨찾기메뉴",
      },
      customer: {
        userId: "거래처 ID",
        cusName: "거래처명",
        mallType: "전자상거래유형",
        cusSiteUrl: "Site URL",
        customsSpcNo: "특별통관부호",
        salesBroker: "판매중개업체명(플랫폼)",
        salesBrokerNo: "판매중개업체등록부호(플랫폼)",
        prchsAgent: "구매(배송)대행업체명",
        prchsAgentNo: "구매(배송)대행업체등록부호",
        osSeller: "해외판매자명(해외직접수입형)",
        osSellerNo: "해외판매자등록부호(해외직접수입형)",
      },
      price: {
        invoice: {
          invAddr: "Invoice 주소",
          invcDueType: "Due Date",
          invcDueDay: "Due Date",
        },
        freight: {
          pageTitle: "구간별 Freight",
          fromWt: "From WT",
          toWt: "To WT",
          basicFee: "건당 기본료",
          dcWt: "할인 WT",
          unitWt: "Per WT",
          dcWtCnt: "추가 할인 WT",
          unitFee: "구간 요금",
        },
        other: {
          pageTitle: "Other 요금",
          priceItemId: "요금 항목",
          basicFee: "건당 요금",
          currency: "적용 Currency",
        },
      },
      api: {
        apiKey: "API Key",
        regenerate: "Regenerate",
        accessIp: "접속 IP",
        submit: "등록신청",
        accessIpStatus: "@:myPage.myInfo.api.accessIp 등록현황",
        downloadApiDoc: "API 규격서 @:common:download",
        confirmSubmit: "접속 IP 등록 하시겠습니까?",
      },
      waybill: {
        hint1: "*체크시 운송장에 출력됩니다.",
        blformItem: "상품명",
        blformBuysite: "구입사이트",
      },
    },
    excelField: {
      tabs: {
        pick: "엑셀양식 선택",
        setting: "엑셀필드 설정",
      },
      pick: {
        pageTitle: "사용할 엑셀양식 선택",
      },
      setting: {
        dialogTitle: "엑셀필드 설정하기",
        blExcelNm: "설정 이름",
        addButton: "양식 추가",
        columnNm: "운송장 항목",
        excelColumn: "엑셀필드",
        fixValue: "고정값",
        editBtn: "설정변경",
        duplicatedExcelColumns: "엑셀필드 값은 중복으로 선택할 수 없습니다.",
      },
    },
    invoice: {
      invcNo: "Invoice No.",
      invcCycle: "Invoice Cycle",
      invcDt: "Invoice Date",
      terms: "Terms",
      invcDueDate: "Due Date",
      hawbQty: "BL Qty.",
      invcCurrency: "Currency",
      invcAmt: "Invoice Amount",
      receivedAmt: "Received Amount",
      balance: "Balance",
      popupTitle: "Invoice 상세",
      hawbNo: "운송장 번호",
      options: {
        title: "Option",
        ORG: "원발생금액",
        CVT: "환율변환금액",
      },
    },
  },
  en: {},
  zhHans: {},
};
