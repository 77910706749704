export default {
  ko: {
    filterDeptDt: "Departure",
    postDlvDate: "배송일",
    deptDt: "출발일(현지시간)",
    arrvDt: "도착일(현지시간)",
    customsDate: "관세일자(한국시간)",
    postDlvName: "수령인",
    deliveryInquiry: "배송조회",
    dutySurtax: {
      wdate: "적용일자",
      cusName: "Company",
      remark: "적요",
      hawbNo: "BL No",
      orderNo: "Order No",
      receiver: "Receiver",
      customsDate: "통관일자",
      dutySurtax: "관부가세",
      chgDuty: "관세",
      chgVat: "부가세",
      chgEtc: "기타",
      chgTotal: "합계금액",
    },
    uncustoms: {
      arrvDt: "도착일시(한국시간)",
      unCustomsReason: "미통관사유",
      replyCtnts: "회신내용",
      customsType: "목록통관구분",
    },
  },
  en: {},
  zhHans: {},
};
