import router from "@/router";
import { type ApiDataResult, postApi, stringifyParams } from "@/utils/apis";
import { useUserStore } from "@/stores/user";
import { parse } from "qs";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import axios from "axios";
import { API_HOST } from "@/constants/envs";
import { type JwtTokens } from "@/definitions/types";
import { useToast } from "vue-toast-notification";
import { isEmpty } from "@/utils/rules";
import { tzDayjs } from "@/utils/tzDayjs";
import { parse as xlsxParser } from "node-xlsx";

function formatPath(val: string): string {
  const _path = val.split("?");
  const uri = _path.splice(0, 1);
  return uri + stringifyParams(parse(_path.join("?")));
}

export async function routerPush(path: string): Promise<void> {
  if (formatPath(router.currentRoute.value.fullPath) === formatPath(path)) {
    return;
  }

  await router.push(path);
}

export async function routerReplace(path: string): Promise<void> {
  if (formatPath(router.currentRoute.value.fullPath) === formatPath(path)) {
    return;
  }

  await router.replace(path);
}

export function isExpiredToken(token: string): boolean {
  return dayjs((jwtDecode(token) as { exp: number }).exp * 1000).isBefore(
    dayjs(),
  );
}

export async function goSignInPage(): Promise<void> {
  const { clearUser } = useUserStore();
  clearUser();
  await routerReplace("/auth/sign-in");
}

export async function signOut(): Promise<void> {
  try {
    await postApi("api/v1/auth/customer-user/logout", null, false);
  } catch (e) {
    console.error(e);
  }
  await goSignInPage();
}

export async function getNewToken(): Promise<JwtTokens | undefined> {
  try {
    const { data } = await axios
      .create({
        baseURL: API_HOST,
        headers: {
          contentType: "application/json",
        },
      })
      .post<ApiDataResult<JwtTokens>>("api/v1/auth/customer-user/refresh", {
        refreshToken: await getValidatedRefreshToken(),
      });
    return data.data;
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      const statusCode = e.response?.status || 500;
      if (statusCode === 401 || e.message === "Invalid token specified!") {
        await goSignInPage();
      } else if ([403, 404, 500].includes(statusCode)) {
        useToast().error(e.message);
      } else {
        console.warn(`Missing Status Code: ${statusCode}`);
        useToast().error(e.message);
      }
    } else {
      console.error(e);
    }
  }
}

export async function getValidatedAccessToken(): Promise<string> {
  let accessToken = window.localStorage.getItem(
    "aci-eshop-user-web-accessToken",
  );
  if (!accessToken || accessToken === "undefined") {
    await goSignInPage();
    return "";
  }

  try {
    if (isExpiredToken(accessToken)) {
      const { reIssueAccessToken } = useUserStore();
      await reIssueAccessToken();
      accessToken = window.localStorage.getItem(
        "aci-eshop-user-web-accessToken",
      );
    }
  } catch (e: unknown) {
    await signOut();
  }
  return accessToken ?? "";
}

export async function getValidatedRefreshToken(): Promise<string> {
  const refreshToken = window.localStorage.getItem(
    "aci-eshop-user-web-refreshToken",
  );
  if (!refreshToken || isExpiredToken(refreshToken)) {
    await goSignInPage();
  }
  return refreshToken ?? "";
}

export function mergeWithFallback(a, b) {
  const result = { ...b }; // 먼저 B의 모든 값으로 초기화

  for (const [key, value] of Object.entries(a)) {
    // A의 값이 undefined 가 아닌 경우, 또는 A에만 존재하는 키일 경우 결과 객체에 설정
    if (!isEmpty(value)) {
      result[key] = value;
    }
  }

  return result;
}

export function getISOStringFromData(value: unknown): string {
  if (
    typeof value === "number" &&
    (value as number).toString().length === 8 &&
    (value as number).toString().startsWith("20") &&
    tzDayjs((value as number).toString(), "YYYYMMDD").isValid()
  ) {
    return tzDayjs((value as number).toString(), "YYYYMMDD").toISOString();
  } else if (
    typeof value === "string" &&
    (value as string).length === 8 &&
    (value as string).startsWith("20") &&
    !isNaN(Number(value)) &&
    tzDayjs(value, "YYYYMMDD").isValid()
  ) {
    return tzDayjs((value as string).toString(), "YYYYMMDD").toISOString();
  } else if (
    typeof value === "string" &&
    (value as string).length === 10 &&
    (value as string).startsWith("20") &&
    tzDayjs(value, "YYYY-MM-DD").isValid()
  ) {
    return tzDayjs((value as string).toString(), "YYYY-MM-DD").toISOString();
  } else if (
    typeof value === "string" &&
    (value as string).length === 19 &&
    (value as string).startsWith("20") &&
    tzDayjs(value, "YYYY-MM-DD HH:MM:SS").isValid()
  ) {
    return tzDayjs(
      (value as string).toString(),
      "YYYY-MM-DD HH:MM:SS",
    ).toISOString();
  }
  return value + "";
}

export function downloadBase64Pdf(base64Data: string) {
  // Base64 데이터의 실제 콘텐츠만 추출 (필요한 경우)
  // 'data:application/pdf;base64,' 접두어를 제거합니다.
  const base64Content = base64Data.startsWith("data:application/pdf;base64,")
    ? base64Data.split(",")[1]
    : base64Data;

  // Base64 문자열을 Uint8Array로 변환
  const byteCharacters = atob(base64Content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const newUrl = window.URL.createObjectURL(
    new Blob([byteArray], { type: "application/pdf" }),
  );
  window.open(newUrl, "_blank");
}

export function getHeaderARowDataFromFileReader(
  fileReader: string | ArrayBuffer | null,
): Record<string, string>[] {
  return (
    xlsxParser<Record<string, string>[]>(fileReader, {
      raw: false,
      blankrows: false,
    })?.[0]?.data ?? []
  ).map((row) => {
    const rowData: Record<string, string> = {};
    row.forEach((cell: unknown, index: number) => {
      const columnName = String.fromCharCode(65 + index); // A부터 시작하는 열 이름 생성
      rowData[columnName] = cell + ""; // 셀 값을 문자열로 변환하여 저장
    });
    return rowData;
  });
}
