import { defineStore } from "pinia";
import { getNewToken, signOut } from "@/utils/commands";
import type { Drawer, JwtTokens } from "@/definitions/types";
import { DRAWERS } from "@/definitions/drawers";
import type { User } from "@/definitions/models";
import { defaultUser } from "@/definitions/models";
import { MenuType } from "@/definitions/selections";
import { cloneDeep } from "lodash-es";

type UserInfo = {
  tokens: JwtTokens;
  user: User;
};

export const useUserStore = defineStore("user", {
  state: (): UserInfo => {
    return {
      user: defaultUser(),
      tokens: {
        access: "",
        refresh: "",
      },
    };
  },
  getters: {
    loggedIn: (state: UserInfo): boolean => {
      return (
        !!state.user.cusLoginId &&
        !!state.tokens.access &&
        !!localStorage.getItem("aci-eshop-user-web-accessToken") &&
        !!localStorage.getItem("aci-eshop-user-web-refreshToken")
      );
    },

    authLevel: (state: UserInfo): number =>
      +(state.user?.mgmtCod?.basicCod ?? "999"),

    developerFlag: (state: UserInfo): boolean =>
      state.user?.mgmtCod?.basicCod === "000",

    cusLoginId: (state: UserInfo): string => state.user?.cusLoginId ?? "",
    takbae: (state: UserInfo): string => state.user?.customer?.takbae ?? "",
    station: (state: UserInfo): string => state.user?.customer?.station ?? "",
    customsDivi: (state: UserInfo): string =>
      state.user?.customer?.customsDivi ?? "",
    drawers: (state: UserInfo): Drawer[] => {
      let _drawers: Drawer[];
      if (state.user?.mgmtCod?.basicCod === "000") {
        _drawers = cloneDeep(DRAWERS);
      } else {
        _drawers = DRAWERS.map((drawer) => ({
          ...drawer,
          children: drawer.children?.filter((child) =>
            state.user.menuIdList?.includes(child.id),
          ),
        })).filter((drawer) => (drawer.children ?? []).length > 0);
      }

      if (_drawers.length > 0 && (state.user?.favMenuIdList ?? []).length > 0) {
        const flatDrawer = _drawers.flatMap((drawer) => drawer.children);
        const favMenu: Drawer = {
          id: "FAV_MENU",
          type: MenuType.GROUP,
          icon: "mdi-star",
          children: state.user.favMenuIdList
            .map((v) => flatDrawer.find((drawer) => drawer?.id === v)!)
            .filter((v) => v),
        };
        _drawers.unshift(favMenu);
      }
      return _drawers;
    },
  },
  actions: {
    clearUser(): void {
      window.localStorage.removeItem("aci-eshop-user-web-accessToken");
      window.localStorage.removeItem("aci-eshop-user-web-refreshToken");
      this.$reset();
    },

    async reIssueAccessToken(): Promise<void> {
      const newToken = await getNewToken();
      if (newToken) {
        this.saveTokens(newToken);
      }
    },

    saveTokens(tokens: JwtTokens): void {
      try {
        window.localStorage.setItem(
          "aci-eshop-user-web-accessToken",
          tokens.access,
        );
        window.localStorage.setItem(
          "aci-eshop-user-web-refreshToken",
          tokens.refresh,
        );
        this.tokens.access = tokens.access;
        this.tokens.refresh = tokens.refresh;
      } catch (e: unknown) {
        signOut().then();
      }
    },
  },
  persist: true,
});
