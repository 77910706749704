<template>
  <CustomLabel :label="label" />
  <v-select
    v-model="modelValue"
    :items="items.filter((v) => v.useYn !== BooleanType.FALSE)"
    class="mx-4"
    density="compact"
    hide-details
    style="max-width: 10rem; height: 36px"
    variant="outlined"
    :disabled="!!disabled"
    :clearable="false"
  />
</template>

<script setup lang="ts">
import type { SelectItem } from "@/definitions/types";
import CustomLabel from "@/views/components/form/CustomLabel.vue";
import { BooleanType } from "@/definitions/selections";

const modelValue = defineModel<string>({ required: true });
defineProps<{
  items: SelectItem[];
  label: string;
  disabled?: boolean;
}>();
</script>

<style lang="scss" scoped>
::v-deep(.v-input__control) {
  > .v-field {
    > .v-field__field {
      > .v-text-field__suffix {
        min-height: 36px !important;
        height: 36px !important;
      }
    }

    > .v-field__overlay {
      height: 36px !important;
    }

    > .v-field__field {
      height: 36px !important;
      > .v-field__input {
        height: 36px !important;
        min-height: 36px !important;
      }
    }

    > .v-field__append-inner {
      height: 36px !important;
    }

    > .v-field__outline {
      height: 36px !important;
    }
  }
}
</style>
