<template>
  <div>
    <template v-for="(item, index) in items">
      <div
        v-if="item.type === 'divider'"
        :key="`divider${index}`"
        class="divider"
      />
      <TextEditorMenuItem
        v-else
        :key="index"
        :action="item.action"
        :icon="item.icon"
        :is-active="item.isActive"
        :title="item.title"
      />
    </template>
    <input
      ref="refFileUpload"
      type="file"
      accept="image/*"
      hidden
      @change="changeFile"
    />
  </div>
</template>

<script setup lang="ts">
import TextEditorMenuItem from "@/views/components/texteditor/TextEditorMenuItem.vue";
import { computed, ref } from "vue";
import type { Editor } from "@tiptap/core";
import { uploadFileApi } from "@/utils/apis";
import { DATE_FORMAT_STRING, ENVIRONMENT } from "@/constants/envs";
import dayjs from "dayjs";
import { v4 as uuidV4 } from "uuid";
import { FileAccessType } from "@/definitions/selections";

const props = defineProps<{
  editor: Editor;
}>();

const refFileUpload = ref();

const items = computed<
  {
    type?: string;
    icon?: string;
    title?: string;
    action?: () => boolean;
    isActive?: () => boolean;
  }[]
>(() => [
  {
    icon: "mdi-format-header-1",
    title: "Heading 1",
    action: () =>
      props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
    isActive: () => props.editor.isActive("heading", { level: 1 }),
  },
  {
    icon: "mdi-format-header-2",
    title: "Heading 2",
    action: () =>
      props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
    isActive: () => props.editor.isActive("heading", { level: 2 }),
  },
  {
    icon: "mdi-format-paragraph",
    title: "Paragraph",
    action: () => props.editor.chain().focus().setParagraph().run(),
    isActive: () => props.editor.isActive("paragraph"),
  },
  {
    type: "divider",
  },
  {
    icon: "mdi-format-bold",
    title: "Bold",
    action: () => props.editor.chain().focus().toggleBold().run(),
    isActive: () => props.editor.isActive("bold"),
  },
  {
    icon: "mdi-format-underline",
    title: "underline",
    action: () => props.editor.chain().focus().toggleUnderline().run(),
    isActive: () => props.editor.isActive("underline"),
  },
  {
    icon: "mdi-format-italic",
    title: "Italic",
    action: () => props.editor.chain().focus().toggleItalic().run(),
    isActive: () => props.editor.isActive("italic"),
  },
  {
    icon: "mdi-format-strikethrough",
    title: "Strike",
    action: () => props.editor.chain().focus().toggleStrike().run(),
    isActive: () => props.editor.isActive("strike"),
  },
  {
    type: "divider",
  },
  {
    icon: "mdi-format-list-bulleted",
    title: "Bullet List",
    action: () => props.editor.chain().focus().toggleBulletList().run(),
    isActive: () => props.editor.isActive("bulletList"),
  },
  {
    icon: "mdi-order-numeric-ascending",
    title: "Ordered List",
    action: () => props.editor.chain().focus().toggleOrderedList().run(),
    isActive: () => props.editor.isActive("orderedList"),
  },
  {
    type: "divider",
  },
  {
    icon: "mdi-image",
    title: "Image",
    action: () => refFileUpload.value.click(),
  },
  {
    icon: "mdi-minus",
    title: "Horizontal Rule",
    action: () => props.editor.chain().focus().setHorizontalRule().run(),
  },
  {
    type: "divider",
  },
  {
    icon: "mdi-format-clear",
    title: "Clear Format",
    action: () =>
      props.editor.chain().focus().clearNodes().unsetAllMarks().run(),
  },
  {
    type: "divider",
  },
  {
    icon: "mdi-undo",
    title: "Undo",
    action: () => props.editor.chain().focus().undo().run(),
  },
  {
    icon: "mdi-redo",
    title: "Redo",
    action: () => props.editor.chain().focus().redo().run(),
  },
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function changeFile(f: any) {
  const file = f.target.files[0];
  const newFileName = uuidV4().concat(
    file.name.slice(file.name.lastIndexOf(".")),
  );
  const dirPath = [
    ENVIRONMENT,
    "text-editor",
    dayjs().format(DATE_FORMAT_STRING),
  ].join("/");
  const response = await uploadFileApi({
    file,
    dirPath,
    fileName: newFileName,
    accessType: FileAccessType.PUBLIC,
    contentType: file.type,
  });
  props.editor
    .chain()
    .focus()
    .setImage({
      src: response,
    })
    .run();
}
</script>

<style lang="scss" scoped>
.divider {
  background-color: rgba(var(--v-theme-black), 0.21);
  height: 1.5rem;
  margin-left: 4px;
  margin-right: 4px;
  width: 1px;
  min-width: 1px;
}
</style>
