import type {
  CreatedUpdatedIdDate,
  FileAttachment,
  User,
  UserSimple,
} from "@/definitions/models";
import { defaultUser } from "@/definitions/models";
import type { BooleanTypesString } from "@/definitions/selections";
import { BooleanType } from "@/definitions/selections";
import type { DateTime } from "@/definitions/types";

export interface Customer extends CreatedUpdatedIdDate {
  userId: string; // User ID
  station: string; // 스테이션
  cusStatus: string; // 거래처상태 그룹코드(CUS_STATUS) (Y: 사용중, H:승인대기, N:거래정지)
  cusName: string; // 거래처명
  cusTel?: string; // 거래처-전화번호
  cusFax?: string; // 거래처_팩스번호
  cusSiteUrl?: string; // 거래처 사이트 URL
  cusAddr?: string; // 거래처-주소
  platformType?: string; // 플랫폼분류 그룹코드 : PLATFORM_TYPE (예) Naver, 쿠팡 등등
  chkNaver?: BooleanTypesString; // Naver, 글로벌윈도우 (Y/N/NULL) 여부
  naverStep?: string; // 네이버 신청 차수, 글로벌 윈도우 차수
  optionKum?: BooleanTypesString; // Work Type - 검품여부 (Y/N)
  optionWtExtent?: number; // Work Type - 검품 - 무게오차범위
  optionMakeBlno?: BooleanTypesString; // Work Type - 거래처 BLNO생성여부 (Y/NULL)
  optionBlExtent?: string; // Work Type - 거래처 BLNO - 대역폭
  optionDelv?: BooleanTypesString; // Work Type - 배송대행업체여부 (Y)
  optionExportPage?: BooleanTypesString; // Work Type - 출고확정 페이지 제공여부 (Y/NULL)
  optionWtDeviation?: number; // 저울 무게 편차
  takeInYn?: BooleanTypesString; // Work Type - 사입 여부 (Y/N/NULL)
  kumBlYn?: BooleanTypesString; // Work Type - 검품BL생성여부 (Y/N/NULL)
  takbae?: string; // Work Type - 거래처 택배사 (화면타이틀 없음) 그룹코드 : TAKBAE CJ (CJ대한통운), CJPUS(CJ 대한통운 부산), CJTAE(CJ대한통운 대구), EPOST(우체국), QXP(Qxp) SHXH(SHXH), IBK(IBK해운)
  shipperName?: string; // Work Type - Suppiler Name
  hawbWtType?: string; // 출고시무게단위 (KG/LB) 그룹코드 : WEG_DAN
  invWtType?: string; // EDI - 무게단위 (KG/LB) 그룹코드 : WEG_DAN
  wegDan?: string; // 무게단위 (KG / LB) 그룹코드 : WEG_DAN
  wtDeviation?: number; // Invoice - 저울부피오차
  customsSpcName?: string; // 특별통관업체명
  customsSpcNo?: string; // 특별통관부호, 구매대행업자 등록부호
  mallType?: string; // 상거래유형 그룹코드 : MALL_TYPE A:해외판매자가직접구매하여발송, B:구매대행업체가 구매하여발송, C: 배송대행 (국내화주가 구매 배대지 이용)
  insuranceRate?: number; // 보험요율
  customsDivi?: string; // Duty & Tax - 관세사구분 그룹코드 : CUSTOMS_DIVI (MUS:MUS, CJI:천지인, DG:대교, SF:자체관세사, CL:청림, DG1:대교-아마맥스)
  taxPay?: string; // 관세지불처 그룹코드 : TAX_PAY C:업체 P:고객
  apiKey?: string; // 인증키값, API Key
  apiIp?: string; // API 접속IP
  saNo?: string; // (검역)사업자번호
  saMallNo?: string; // (검역)통신판매업신고번호
  saAddr?: string; // (검역)사업장주소
  saCeo?: string; // (검역)대표자
  foodChk?: BooleanTypesString; // 식품 - 신고여부 (Y/N)
  foodNo?: string; // 식품 - 신고 영업등록번호
  invCusCode?: string; // Seller
  invAddr?: string; // Invoice 주소
  attnName?: string; // Invoice -담당자명
  attnTel?: string; // Invoice -담당자-전화번호
  attnEmail?: string; // Invoice -담당자-EMAIL
  depositYn?: BooleanTypesString; // Invoice - 예치금 사용여부
  idxDepositId?: string; // Invoice - 예치금 인덱스 ID (예치금 lang_userID)
  invcCycle?: string; // Inovice cycle 그룹코드 : INVC_CYCLE (M:Monthly, W:Weekly, H:Half-Monthly, D:Daily)
  invcDueType?: string; // Invoice due date 유형 그룹코드 : INVC_DUE_TYPE (M1:익월, M2:익익월, INVD: Invoice date+)
  invcDueDay?: number; // Due date 일자 (예)1, 5, 10 등
  delvStation?: string; // 배송 스테이션
  blformItem?: string; // 운송장출력여부 : 상품명
  blformOrderno?: string; // 운송장출력여부 : 주문번호
  blformBuysite?: string; // 운송장출력여부 : 구입사이트
  blformComment?: string; // 운송장출력 COMMENT
  returnDvlType?: string; // 반품정보Customs 그룹코드 : RETURN_DVL_TYPE (A:긴급배송, B:일반배송)
  chkQ10?: BooleanTypesString; // Q10 여부
  priceId?: number; // Price Plan id (SEQ_PRICE_ID.NEXTVAL)
  atchId?: number; // 첨부파일 ID
  attachments: FileAttachment[]; // 첨부파일
  osSeller?: string; // 해외판매자명
  osSellerNo?: string; // 해외판매자등록부호
  prchsAgent?: string; // 구매대행업체명
  prchsAgentNo?: string; // 구매대행업체등록부호
  salesBroker?: string; // 판매중개업체명
  salesBrokerNo?: string; // 판매중개업체 등록부호
  wtVolumePer?: number; // 부피적용율
  invcVolumeApp?: string; // 부피무게로만 청구여부 (Y/N)
  invcCurrency?: string; // Invoice 기준currency (그룹코드 : CURRENCY)
  cusPw?: string; // 거래처 패스워드
  userEmail?: string; // 이메일
}

export function defaultCustomer(): Customer {
  return {
    userId: "",
    station: "",
    cusStatus: "",
    cusName: "",
    attachments: [],
  };
}

export interface CustomerUser extends User {
  customer: Customer;
}

export function defaultCustomerUser(): CustomerUser {
  return {
    ...defaultUser(),
    customer: defaultCustomer(),
  };
}

export interface CustomerUserUpdate {
  cusName: string; // 거래처명
  cusTel?: string; // 전화번호
  cusSiteUrl?: string; // Site URL
  cusAddr?: string; // 거래처 주소
  mallType: string; // 상거래유형 그룹코드 : MALL_TYPE A:해외판매자가직접구매하여발송, B:구매대행업체가 구매하여발송, C: 배송대행 (국내화주가 구매 배대지 이용)
  osSeller?: string; // 해외판매자명
  osSellerNo?: string; // 해외판매자등록부호
  prchsAgent?: string; // 구매대행업체명
  prchsAgentNo?: string; // 구매대행업체등록부호
  salesBroker?: string; // 판매중개업체명
  salesBrokerNo?: string; // 판매중개업체 등록부호
  userEmail: string; // 이메일
  cusPw?: string; // 패스워드
}

export interface Invoice {
  attnName?: string; // 담당자명
  attnTel?: string; // 전화번호
  attnEmail?: string; // EMAIL
  invAddr: string; // Invoice주소
  wtDeviation?: number; // 저울부피오차
  invcCycle: string; // Invoice Cycle
  invcDueType: string; // Due Date - Type
  invcDueDay: number; // Due Date - Day
  idxDepositId?: string; // 예치금Lang User
  depositYn: BooleanTypesString; // 예치금 사용여부
  invcCurrency: string; // Invoice Currency
}

// 구간별 Freight
export interface SectionFreight {
  fromWt: number; // From WT (kg) - Format: ##0.0
  toWt: number; // To WT (kg) - Format: ##0.0
  basicFee: number; // ① 건당기본료 - Format: ##0.0
  dcWt: number; // ②할인WT (kg) - Format: ###,##0.00
  unitWt: number; // ③perWT (kg) - Format: ##0.0
  dcWtCnt: number; // ④추가 할인WT - Format: ##0.0
  unitFee: number; // ⑤구간요금 - Format: ###,##0.00
}

// Other 요금
export interface OtherFee {
  priceItemCd?: string; // 요금항목
  priceItemDesc?: string; // 요금항목
  basicFee?: number; // 건당요금, Format: ###,##0.00
  currency?: string; // 적용 Currency
  invcStation?: string; // 청구스테이션
}

export interface Price {
  invoice: Invoice;
  sectionFreightList: SectionFreight[];
  otherFees: OtherFee[];
}

export interface ApiData {
  apiKey: string;
  accessIp: string[];
}

export function defaultApiData(): ApiData {
  return {
    apiKey: "",
    accessIp: [],
  };
}

export interface ApiCreate {
  accessIp: string;
}

export interface Waybill {
  blformOrderno: BooleanTypesString; // 주문번호
  blformItem: BooleanTypesString; // 상품명
  blformBuysite: BooleanTypesString; // 구입사이트
  blformComment?: string; // Comment

  updater?: UserSimple;
  crtDt?: DateTime;
  updDt?: DateTime;
}

export function defaultWaybill(): Waybill {
  return {
    blformOrderno: BooleanType.FALSE,
    blformItem: BooleanType.FALSE,
    blformBuysite: BooleanType.FALSE,
  };
}
