<template>
  <div class="d-flex flex-grow-1">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      class="elevation-1"
      floating
      light
    >
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="py-2 px-6">
          <v-img
            src="@/assets/images/aci_logo.png"
            contain
            width="120"
            height="56.97"
          />
          <div
            class="text-overline text-blue-grey"
            style="color: #777777; text-transform: none !important"
          >
            {{ PRODUCT_VERSION }}&nbsp;&nbsp;{{ cusLoginId }}
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
      <v-list class="py-0" density="compact" nav>
        <NavMenu :drawers="drawers" />
      </v-list>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar :flat="false" app light>
      <v-card :flat="true" class="flex-grow-1 d-flex pa-0 ma-0">
        <div class="d-flex flex-grow-1 align-center">
          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon
              size="48"
              style="margin-right: 12px"
              @click.stop="drawer = !drawer"
            />

            <RollingNoticeBar v-if="loggedIn" />

            <v-spacer />

            <Select36pxOutlined
              v-model="language"
              :label="t('common.language')"
              :items="LANG_TYPES"
            />
            <v-divider
              vertical
              length="12"
              inset
              color="#C8C8C8"
              style="margin-top: 18px; margin-right: 1rem; opacity: unset"
            />

            <ToolbarUser />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
        :fluid="true"
        class="fill-height"
        style="align-items: normal"
      >
        <v-layout>
          <slot />
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts" setup>
import NavMenu from "@/views/components/navigation/NavMenu.vue";
import { nextTick, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { LANG_TYPES } from "@/definitions/selections";
import ToolbarUser from "@/views/components/toolbar/ToolbarUser.vue";
import { PRODUCT_VERSION } from "@/constants/envs";
import { useConfigStore } from "@/stores/config";
import { useUserStore } from "@/stores/user";
import { useI18n } from "vue-i18n";
import RollingNoticeBar from "@/views/components/notice/RollingNoticeBar.vue";
import Select36pxOutlined from "@/views/components/form/Select36pxOutlined.vue";
import {
  getValidatedAccessToken,
  getValidatedRefreshToken,
} from "@/utils/commands";

const { t } = useI18n();

const drawer = ref(true);

const { language } = storeToRefs(useConfigStore());

watch(
  language,
  async (val, oldVal) => {
    localStorage.setItem("locale", val);
    if (oldVal !== undefined && oldVal !== val) {
      await nextTick();
      window.document.location.reload();
    }
  },
  { immediate: true },
);
const { drawers, loggedIn, cusLoginId } = storeToRefs(useUserStore());

onBeforeMount(async () => {
  await getValidatedRefreshToken();
  await getValidatedAccessToken();
});

// 모든 탭에서 실행될 코드
const channel = new BroadcastChannel("login-event");
// 메시지 수신 처리
channel.onmessage = () => {
  window.location.reload();
};
onBeforeUnmount(() => {
  channel.close();
});
</script>
