import type { DateTime } from "@/definitions/types";
import type {
  BooleanTypesString,
  CrudFlagTypesString,
  FileAccessTypesString,
  LangTypesString,
} from "@/definitions/selections";
import {
  BooleanType,
  CrudFlagType,
  FileAccessType,
} from "@/definitions/selections";
import type { Customer } from "@/views/my-page/my-info/models";
import { defaultCustomer } from "@/views/my-page/my-info/models";

export interface UserSimple {
  loginId: string;
  loginType: string;
  userName: string;
  userSeq: string;
  useYn: BooleanTypesString;
}
export interface CreatedUpdatedIdDate {
  crtId?: string;
  creator?: UserSimple;
  updId?: string;
  updater?: UserSimple;
  crtDt?: DateTime;
  updDt?: DateTime;
}

export interface IpCreatedUpdatedIdDate extends CreatedUpdatedIdDate {
  crtIp?: string;
  updIp?: string;
}
export interface IdxCreatedUpdatedIdDate extends CreatedUpdatedIdDate {
  idx?: number;
}

export interface IdxIpCreatedUpdatedIdDate extends IdxCreatedUpdatedIdDate {
  crtIp?: string;
  updIp?: string;
}

export interface Code extends CreatedUpdatedIdDate {
  groupCod: string;
  basicCod: string;
  langType: LangTypesString;
  codDesc: string;
  useYn: BooleanTypesString;
  station: string;
  etc1?: string;
  etc2?: string;
  etc3?: string;
  note?: string;
  sortNo: number;
}

export type RadioItem = { title: string; value: string };

export type SelectTextFilterProps = {
  select: string | null;
  search: string | null;
};

export type Station = {
  station: string;
  stationName: string;
  stationCurrency: string;
  stationComment: string;
  stationAddr: string;
  stationTel: string;
  stationFax: string;
  depositBank: string;
  depositName: string;
  depositAccount: string;
  useYn: BooleanTypesString;
  stationTwoCode: string;
  stationCity: string;
  updId: string;
  updDt: DateTime;
};

export interface FileAttachment extends CreatedUpdatedIdDate {
  atchIdx?: number; // 첨부파일 pk
  atchId?: number; // 첨부파일 ID
  accessType: FileAccessTypesString; // 접근 유형(public, private)
  fileTitle?: string; // 첨부파일 제목
  fileName: string; // 첨부파일 명
  fileUri: string; // 첨부파일 URI
  fileSize?: number; // 첨부파일 사이즈 (KB)
  fileType?: string; // 첨부파일 종류/확장자
  crudFlag: CrudFlagTypesString;
}

export function defaultFileAttachment(): FileAttachment {
  return {
    crudFlag: CrudFlagType.I,
    accessType: FileAccessType.PUBLIC,
    fileName: "",
    fileUri: "",
  };
}

export interface User extends CreatedUpdatedIdDate {
  cusLoginId: string;
  cusUsrName: string;
  userEmail?: string;
  useYn: BooleanTypesString;
  authLevel: string;
  comment?: string;
  menuIdList: string[] | null;
  mgmtCod?: Code;
  favMenuIdList: string[];
  loginFailCnt: number;
  pwdExpiryDt: DateTime;
  pwdUpdDt: DateTime;
  customer: Customer;
}

export function defaultUser(): User {
  return {
    cusLoginId: "",
    cusUsrName: "",
    useYn: BooleanType.FALSE,
    authLevel: "",
    menuIdList: [],
    favMenuIdList: [],
    loginFailCnt: 0,
    pwdExpiryDt: null,
    pwdUpdDt: null,
    customer: defaultCustomer(),
  };
}

export interface ExcelData {
  __rowNum: number;
  __errorMsg?: string;
  __warnMsg?: string;
  __details: { __errorMsg?: string; __warnMsg?: string }[];
}
export interface ExcelValidationApiData {
  prepedExcelRows: ExcelData[];
  totalMasterCount: number;
}
