import type { CancelTokenSource } from "axios";
import axios, {
  type AxiosError,
  AxiosHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
  type RawAxiosResponseHeaders,
} from "axios";
import { API_HOST, CDN_URL, ENVIRONMENT } from "@/constants/envs";
import {
  downloadBase64Pdf,
  getValidatedAccessToken,
  goSignInPage,
} from "@/utils/commands";
import { stringify } from "qs";
import { useToast } from "vue-toast-notification";
import type { DateTime, ListApiResult, SelectItem } from "@/definitions/types";
import { useConfigStore } from "@/stores/config";
import { getFilenameFromUrl } from "@/utils/formatter";
import {
  BooleanType,
  type FileAccessTypesString,
} from "@/definitions/selections";
import dayjs from "dayjs";
import i18n from "@/plugins/vue-i18n";
import type { Code } from "@/definitions/models";
import { v4 as uuidV4 } from "uuid";
import { isEmpty } from "@/utils/rules";
import type { Ref } from "vue";

export const axiosInstance = axios.create({
  baseURL: `${API_HOST}`,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

export interface ApiDataResult<T = unknown> {
  success?: boolean;
  code: string;
  message: string;
  data: T;
}

const NO_RESPONSE: ApiDataResult = {
  success: false,
  code: "F0000",
  message: i18n.global.t("common.result.noData"),
  data: null,
} as const;

export const pendingRequests = new Map<string, CancelTokenSource>();

axiosInstance.interceptors.request.use(
  async function (config) {
    if (!config.headers) {
      console.error("Failed to set 'request headers' : headers is not exist");
      return {} as InternalAxiosRequestConfig;
    }
    const { language, station } = useConfigStore();
    config.headers["X-Language-Type"] = language;
    config.headers["X-Working-Station"] = station;
    config.headers["X-Timezone"] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers.Authorization = `Bearer ${await getValidatedAccessToken()}`;

    // 요청 ID 생성
    const requestId = uuidV4();
    const cancelToken = axios.CancelToken.source();

    config.cancelToken = cancelToken.token;
    config.headers.requestId = requestId; // meta 객체가 없을 수도 있으므로 스프레드 연산자를 사용

    // 요청 ID를 키로 사용하여 cancelToken 저장
    pendingRequests.set(requestId, cancelToken);

    return config;
  },
  function (error) {
    toastAxiosError(error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    const requestId = response.config.headers.requestId;
    pendingRequests.delete(requestId);

    if (!response) {
      return {
        data: NO_RESPONSE,
      } as AxiosResponse<ApiDataResult>;
    } else if (response?.status === 204) {
      if (response.data) {
        response.data = { ...response.data, success: true };
      } else {
        response.data = {
          success: true,
          code: "S000",
          message: i18n.global.t("common.result.noData"),
          data: null,
        };
      }
      return response;
    } else if (
      // 파일 다운로드 케이스 일때는 response 즉시 리턴
      !((response.headers["content-type"] as string) ?? "").startsWith(
        "application/json",
      )
    ) {
      return response;
    } else if (response?.data) {
      response.data.success = !!response?.data?.code?.includes("S");
      return response;
    } else {
      console.error(response);
      response.data = {
        ...response.data,
        success: false,
      };
      return response;
    }
  },
  async function (error: AxiosError) {
    console.error(error.message);
    const requestId = error.config?.headers?.requestId;
    requestId && pendingRequests.delete(requestId);
    if (error.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }

    if (error.message === "Network Error") {
      useToast().error(i18n.global.t("common.result.serviceUnavailable"));
      return;
    }
    if (error.response) {
      if ([400, 403, 404, 500].includes(error.response.status)) {
        if (error.response.status === 403) {
          useToast().error(getErrorMessage(error));
        }
        throw error;
      } else if ([401].includes(error.response.status)) {
        await goSignInPage();
        return;
      }
    }
    if (ENVIRONMENT === "local") {
      toastAxiosError(error);
    }
    console.error(error);
    return Promise.reject(error);
  },
);

export async function getApi<T = never, R = T>(
  url: string,
): Promise<ApiDataResult<R>> {
  try {
    const response =
      (await axiosInstance.get<T, AxiosResponse<ApiDataResult<R>>>(url))
        ?.data ?? NO_RESPONSE;
    if (!response?.success) {
      console.warn(response?.message);
    }
    return response;
  } catch (e) {
    console.error(e);
    console.warn(i18n.global.t("common.result.noData"));
    throw e;
  }
}

export async function postApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alert = true,
): Promise<ApiDataResult<R>> {
  try {
    const response =
      (await axiosInstance.post<T, AxiosResponse<ApiDataResult<R>>>(url, data))
        ?.data ?? NO_RESPONSE;
    if (alert) {
      alertResponseMessage(response);
    }
    return response;
  } catch (e) {
    console.error(e);
    if (alert) {
      toastAxiosError(e);
    }
    throw e;
  }
}

export async function putApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alert = true,
): Promise<ApiDataResult<R>> {
  try {
    const response =
      (await axiosInstance.put<T, AxiosResponse<ApiDataResult<R>>>(url, data))
        ?.data ?? NO_RESPONSE;
    if (alert) {
      alertResponseMessage(response);
    }
    return response;
  } catch (e) {
    console.error(e);
    if (alert) {
      toastAxiosError(e);
    }
    throw e;
  }
}

export async function patchApi<T = never, R = T>(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  data: any,
  alert = true,
): Promise<ApiDataResult<R>> {
  try {
    const response =
      (await axiosInstance.patch<T, AxiosResponse<ApiDataResult<R>>>(url, data))
        ?.data ?? NO_RESPONSE;
    if (alert) {
      alertResponseMessage(response);
    }
    return response;
  } catch (e) {
    console.error(e);
    if (alert) {
      toastAxiosError(e);
    }
    throw e;
  }
}

export async function deleteApi<T = never, R = T>(
  url: string,
  data?: T,
  alert = true,
): Promise<ApiDataResult<R>> {
  try {
    const response =
      (
        await axiosInstance.delete<T, AxiosResponse<ApiDataResult<R>>>(url, {
          data,
        })
      )?.data ?? NO_RESPONSE;
    if (alert) {
      const toast = useToast();
      if (response?.success) {
        toast.success(i18n.global.t("common.result.remove"));
      } else {
        toast.error(
          response.message
            ? response.message
            : i18n.global.t("common.result.failure"),
        );
      }
    }
    return response;
  } catch (e) {
    console.error(e);
    if (alert) {
      toastAxiosError(e);
    }
    throw e;
  }
}

export async function getCodesApi(groupCode: string): Promise<SelectItem[]> {
  const item = window.sessionStorage.getItem(groupCode);
  if (item) {
    const parsed = JSON.parse(item) as {
      created: DateTime;
      items: SelectItem[];
    };
    if (
      parsed.items.length > 0 &&
      dayjs(parsed.created).add(1, "day").isAfter(dayjs())
    ) {
      // eslint-disable-next-line no-console
      console.info(`${groupCode} from cache => `, parsed.items);
      return parsed.items;
    }
  }

  try {
    const { success, data } =
      (
        await axiosInstance.get<
          string,
          AxiosResponse<ApiDataResult<ListApiResult<Code>>>
        >(
          `api/v1/mgmt-cods/?${stringifyParams({
            groupCod: groupCode,
            page: 1,
            size: 1000,
          })}`,
        )
      )?.data ?? NO_RESPONSE;
    if (success && data?.items) {
      const items = (data?.items ?? []).map((v) => ({
        value: v.basicCod,
        title: v.codDesc,
        useYn: v.useYn,
      }));
      window.sessionStorage.setItem(
        groupCode,
        JSON.stringify({ created: dayjs().toISOString(), items }),
      );
      return items;
    }
    return [];
  } catch (error) {
    return [] as SelectItem[];
  }
}

export async function getCodesApiByUseYnIsNotN(
  groupCode: string,
  viewModeFlag = false,
): Promise<SelectItem[]> {
  return (await getCodesApi(groupCode)).filter(
    (v) => viewModeFlag || v.useYn !== BooleanType.FALSE,
  );
}

export function getErrorMessage(e: unknown) {
  return (
    (e as AxiosError).message ??
    i18n.global.t("common.result.serviceUnavailable")
  );
}

export function toastAxiosError(e: unknown): void {
  useToast().error(getErrorMessage(e));
}

export async function getPromiseExcelApi(
  url: string,
  payload?: unknown,
): Promise<AxiosResponse<Blob>> {
  return (
    (payload
      ? await axiosInstance.post<Blob>(url, payload, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
      : await axiosInstance.get<Blob>(url, {
          responseType: "blob",
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
        })) ?? {}
  );
}

export async function getPromisePdfApi(
  url: string,
  payload?: unknown,
): Promise<AxiosResponse<Blob>> {
  return (
    (payload
      ? await axiosInstance.post<Blob>(url, payload, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
      : await axiosInstance.get<Blob>(url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/pdf",
          },
        })) ?? {}
  );
}

export async function downloadFile(
  data: Blob,
  headers: RawAxiosResponseHeaders | (RawAxiosResponseHeaders & AxiosHeaders),
  url: string,
  refLoading?: Ref<boolean>,
) {
  if (data.type === "application/json") {
    try {
      const text = await data.text();
      const jsonData: ApiDataResult = JSON.parse(text);
      useToast().error(
        jsonData.message ?? i18n.global.t("common.downloadFail"),
      );
    } catch {
      useToast().error(i18n.global.t("common.downloadFail"));
    }
    return;
  }
  if (refLoading) {
    refLoading.value = true;
  }
  try {
    const newUrl = window.URL.createObjectURL(
      new Blob([data], { type: headers["content-type"] }),
    );
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = newUrl;
    const contentDisposition = headers["content-disposition"];
    const fileName = contentDisposition
      ? contentDisposition
          .split("=")
          .pop()
          ?.split(";")
          .join("")
          // eslint-disable-next-line
          .split('"')
          .join("")
      : url.split("/")?.pop();
    if (fileName) {
      tempLink.setAttribute("download", decodeURI(fileName));
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(newUrl);
  } finally {
    if (refLoading) {
      refLoading.value = false;
    }
  }
}

export async function downloadExcelApi(
  url: string,
  payload?: unknown,
  refLoading?: Ref<boolean>,
): Promise<void> {
  if (refLoading) {
    refLoading.value = true;
  }
  try {
    const { headers, data } = await getPromiseExcelApi(url, payload);
    await downloadFile(data, headers, url);
  } finally {
    if (refLoading) {
      refLoading.value = false;
    }
  }
}

export async function downloadPdfApi(
  url: string,
  payload?: unknown,
  refLoading?: Ref<boolean>,
): Promise<void> {
  if (refLoading) {
    refLoading.value = true;
  }
  try {
    const { headers, data } = await getPromisePdfApi(url, payload);
    if (data.type === "application/json") {
      try {
        const text = await data.text();
        const jsonData: ApiDataResult = JSON.parse(text);
        useToast().error(
          jsonData.message ?? i18n.global.t("common.downloadFail"),
        );
      } catch {
        useToast().error(i18n.global.t("common.downloadFail"));
      }
      return;
    }
    const newUrl = window.URL.createObjectURL(
      new Blob([data], { type: headers["content-type"] }),
    );
    window.open(newUrl, "_blank");
  } finally {
    if (refLoading) {
      refLoading.value = false;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyParams(obj: any): string {
  return stringify(
    Object.keys(obj)
      .filter((key) => typeof obj[key] !== "string" || obj[key].trim() !== "")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((prev: any, key) => {
        prev[key] = obj[key];
        return prev;
      }, {}),
    { arrayFormat: "repeat", skipNulls: true },
  );
}

function alertResponseMessage(data: ApiDataResult): void {
  const toast = useToast();
  if (data.success || data.code.startsWith("S")) {
    toast.success(data.message);
  } else {
    toast.error(data.message);
  }
}

export async function getPresignedUploadURLApi({
  dirPath,
  fileName,
  accessType,
  contentType,
}: {
  dirPath: string;
  fileName: string;
  accessType: FileAccessTypesString;
  contentType?: string;
}): Promise<string> {
  const { data } = await axios.get<string>(
    `${API_HOST}api/v1/presigned-upload-url?${stringify({
      dirPath,
      fileName,
      accessType,
      contentType,
    })}`,
    {
      headers: {
        Authorization: `Bearer ${await getValidatedAccessToken()}`,
      },
    },
  );
  return data;
}

async function postPresignedUploadURLApi(
  preSignedUrl: string,
  file: File,
): Promise<number> {
  try {
    const { status } = await axios.put(preSignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    return status;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function uploadFileApi({
  file,
  dirPath,
  fileName,
  accessType,
  contentType,
}: {
  file: File;
  dirPath: string;
  fileName: string;
  accessType: FileAccessTypesString;
  contentType?: string;
}): Promise<string> {
  const response = await getPresignedUploadURLApi({
    dirPath,
    fileName,
    accessType,
    contentType,
  });
  try {
    await postPresignedUploadURLApi(response, file);
    return CDN_URL + dirPath.concat("/", getFilenameFromUrl(response));
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function downloadFileByUrl(url: string, filename?: string) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = function () {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = filename ?? getFilenameFromUrl(url);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  xhr.open("GET", url);
  xhr.send();
}
export async function downloadPrintPdf(
  printType: "A4" | "LABEL" | "SIDE" | "SIDE2",
  takbae: "CJ" | "EPOST" | "EPOSTOD" | "Q10A" | "Q10C" | "CJZPL",
  hawbNos: string[],
  refLoading?: Ref<boolean>,
): Promise<boolean> {
  if (isEmpty(hawbNos)) {
    useToast().error(i18n.global.t("common.result.noParams"));
    return false;
  }

  if (refLoading) {
    refLoading.value = true;
  }
  try {
    const { success, data } = await postApi<unknown, { base64Pdf?: string }>(
      `/api/v1/orders/print-pdf`,
      {
        printType,
        hawbInfo: hawbNos.map((v) => ({
          hawbNo: v,
          takbae,
        })),
        buttonType: takbae,
      },
    );
    if (success) {
      if (data?.base64Pdf) {
        downloadBase64Pdf(data.base64Pdf);
      }
    }
    return !!success;
  } finally {
    if (refLoading) {
      refLoading.value = false;
    }
  }
}
