export default {
  ko: {
    title: "Confirm",
    remove: "@:button.remove{'하시겠습니까'}?",
    save: "작성한 내용을 @:button.save{'하시겠습니까?'}",
    edit: "@:button.edit{'하시겠습니까'}?",
    confirmPasswordLabel: "@:common.password 확인",
    confirmPasswordPlaceholder: "@:common.password 입력해주세요.",
  },
  en: {},
  zhHans: {},
};
