export const PRODUCT_TITLE: string = import.meta.env.VITE_PRODUCT_TITLE;
export const PRODUCT_VERSION: string = import.meta.env.VITE_PRODUCT_VERSION;
export const API_HOST: string = import.meta.env.VITE_BASE_API_URL;
export const LOCALE: string = import.meta.env.VITE_LOCALE;
export const DATE_TIME_ZONE: string = import.meta.env.VITE_DATE_TIME_ZONE;
export const DATE_FORMAT_STRING: string = import.meta.env
  .VITE_DATE_FORMAT_STRING;
export const DATETIME_FORMAT_STRING: string = import.meta.env
  .VITE_DATETIME_FORMAT_STRING;
export const TIME_FORMAT_STRING: string = import.meta.env
  .VITE_TIME_FORMAT_STRING;
export const DATETIME_MINUTE_FORMAT_STRING: string = import.meta.env
  .VITE_DATETIME_MINUTE_FORMAT_STRING;
export const TIME_MINUTE_FORMAT_STRING: string = import.meta.env
  .VITE_TIME_MINUTE_FORMAT_STRING;
// export const SENTRY_DSN: string = import.meta.env.VITE_SENTRY_DSN;
export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;
export const CDN_URL: string = import.meta.env.VITE_CDN_URL;
