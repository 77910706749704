import { getCodesApi } from "@/utils/apis";

export function fetchFullData() {
  const CODES = new Set([
    "AUTH_LEVEL",
    "CHRG_CHK_TYPE",
    "COMMENT_TYPE",
    "CURRENCY",
    "CUS_ACT_TYPE",
    "CUS_STATUS",
    "CUSTOMS_DIVI",
    "FRG_METHOD",
    "FOOD_DIVI",
    "GET_BY",
    "HAPSAN_TYPE",
    "HAWB_ACT_TYPE",
    "MAWB_STATUS",
    "INVC_CYCLE",
    "INVC_DUE_TYPE",
    "JOB_TYPE",
    "MALL_TYPE",
    "PLATFORM_TYPE",
    "PUCH_STATUS",
    "RECEIVED_KIND",
    "REQ_STATUS",
    "RETURN_DVL_TYPE",
    "STOCK_STATUS",
    "TAKBAE",
    "TAKE_IN_STATUS",
    "TAX_PAY",
    "WEGDAN",
    "WH_STATUS",
  ]);
  CODES.forEach((v) => window.sessionStorage.removeItem(v));
  CODES.forEach((v) => getCodesApi(v).then());
  window.sessionStorage.removeItem("API_STATION");
}
