import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { useI18n } from "vue-i18n";
import i18n from "@/plugins/vue-i18n";

export default createVuetify({
  components,
  directives,
  locale: {
    adapter: createVueI18nAdapter({
      i18n,
      useI18n,
    }),
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#DB2E6E",
          secondary: "#100036",
          accent: "#FBC400",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
          black: "#000000", // border: rgba(0, 0, 0, 0.21)
        },
      },
    },
  },
  defaults: {
    global: {
      density: "compact",
      hideDetails: "auto",
      persistentPlaceholder: true,
      persistentHint: true,
      clearable: true,
      dense: true,
    },
    VTextField: {
      hideDetails: "auto",
      color: "primary",
      persistentPlaceholder: true,
    },
    VSelect: {
      hideDetails: "auto",
      color: "primary",
      persistentPlaceholder: true,
    },
    VTextarea: {
      hideDetails: "auto",
      color: "primary",
      persistentPlaceholder: true,
      rows: 1,
      maxRows: 10,
      autoGrow: true,
    },
    VSwitch: {
      hideDetails: "auto",
      falseValue: "N",
      trueValue: "Y",
      color: "primary",
    },
    VAutocomplete: {
      hideDetails: "auto",
      color: "primary",
      persistentPlaceholder: true,
    },
    VCombobox: {
      hideDetails: "auto",
      color: "primary",
      persistentPlaceholder: true,
    },
    VCheckbox: {
      hideDetails: "auto",
      falseValue: "N",
      trueValue: "Y",
      color: "primary",
      baseColor: "primary",
    },
    VRadioGroup: {
      hideDetails: "auto",
      inline: true,
      color: "primary",
      baseColor: "primary",
    },
    VBtn: {
      density: "comfortable",
      color: "#000000",
      variant: "flat",
      elevation: 0,
      flat: true,
      height: 32,
    },
    VFileInput: {
      hideDetails: "auto",
      color: "primary",
    },
    VCard: {
      elevation: 0,
    },
    VBottomSheet: {
      inset: true,
      scrollable: true,
      persistent: true,
      modelValue: true,
      noClickAnimation: true,
    },
  },
});
