export default {
  ko: {
    required: "입력 필수입니다!",
    required1: "하나 이상의 데이터를 등록해주세요.",
    minLength: "{min}자 이상 입력해야 합니다!",
    maxLength: "{max}자 까지 입력해야 합니다!",
    minValueThan: "{min}보다 큰 값을 입력해야 합니다!",
    maxValueThan: "{max}보다 작은 값을 입력해야 합니다!",
    isEmail: "올바르지 않은 이메일 형식입니다!",
    isAlphanumeric: "영문, 숫자만 입력해야 합니다!",
    isDigits: "숫자만 입력해야 합니다!",
    isNumeric: "숫자와 음수부호(-), 소수점(.) 입력만 가능합니다!",
    isValidPassword:
      "영문, 숫자, 특수문자(!{'@'}#{'$'}%^*_)를 혼합하여 8자리~30자리 문자로 구성해야 합니다!",
    YYYYMMDD: "YYYY-MM-DD 형식으로 입력해주세요.",
  },
  en: {},
  zhHans: {},
};
