export default {
  ko: {
    ftaInvoice: "FTA 협정",
    header: {
      ftaSequence: "FTA 협정 등록 순서",
      ftaAnnouncement: "FTA 원산지증명 발급 안내",
      ftaAgree: "FTA 협정문구",
      noNumber: "인증수출자 번호 없는 경우",
      existsNumber: "인증수출자 번호 있는 경우",
    },
    announceContent: {
      sentence1: "수출자는 FTA 원산지증명을 하는 경우 다음의 의무가 있습니다.",
      sentence2:
        "물품 공급자(생산자)로부터 원산지를 확인할 수 있는 원산지확인서(Declaration of Origin) 수취의무",
      sentence3: "원산지증명서, 소명자료의 보관의무와 사후검증 시 입증의무",
      sentence4:
        "서류보관의무 위반 또는 원산지 입증불가 시 관련법령에 따른 벌칙 및 수입자의 추징액 보상의무",
      sentence5:
        "만약, 수출자의 의무이행이 어려운 경우 FTA 원산지증명을 해서는 안됩니다.",
      sentence6: "원산지증명에 동의하시면 아래 절차를 진행해 주시기 바랍니다.",
      sentence7:
        "Check Box (동의함)에 'V' 표시를 하시고 인보이스에 FTA 신고문안이 작성되도록 해주세요.",
      sentence8:
        "인보이스를 출력하셔서 FTA 신고문안 하단에 수출자(작성자)의 성명과 서명을 수기로 작성해 주세요.",
    },
    sequenceContent: {
      one: "동의 체크",
      two: "FTA 협정문구 등록",
      three: "운송장 번호 등록",
      four: "협정 FTA Print 또는 excel 클릭",
    },
  },
  en: {
    ftaInvoice: "FTA Inovoice",
    header: {
      ftaSequence: "FTA 협정 등록 순서",
      ftaAnnouncement: "FTA Certificate of Origin Issuance Guide",
      ftaAgree: "FTA 협정문구",
    },
    announceContent: {
      sentence1:
        "When an exporter applies for a FTA Certificate of Origin, the exporter has certain obligations:",
      sentence2:
        "Obligation to receive a Declaration of Origin from the product supplier (manufacturer) to confirm the origin.",
      sentence3:
        "Obligation to maintain and provide evidence of the Certificate of Origin, supporting documents, and, when required, provide proof during post-verification.",
      sentence4:
        "Violation of the obligation to maintain documents or the inability to prove the origin as per the relevant laws may result in penalties and the duty to reimburse the importer.",
      sentence5:
        "If it is difficult for the exporter to fulfill these obligations, the FTA Certificate of Origin should not be applied.",
      sentence6:
        "If you agree to the Certificate of Origin, please follow the steps below:",
      sentence7:
        "Mark a 'V' in the agreement box and ensure that the FTA declaration is filled out on the invoice.",
      sentence8:
        "Print the invoice and manually write the name and signature of the exporter (writer) at the bottom of the FTA declaration.",
    },
    sequenceContent: {
      one: "① 동의 체크",
      two: "② FTA 협정문구 등록",
      three: "③ 운송장 번호 등록",
      four: "④ 협정 FTA Print 또는 excel 클릭",
    },
  },
  zhHans: {
    ftaInvoice: "自由贸易协定发票",
    header: {
      ftaSequence: "FTA 협정 등록 순서",
      ftaAnnouncement: "FTA Certificate of Origin Issuance Guide",
      ftaAgree: "FTA 협정문구",
    },
    announceContent: {
      sentence1:
        "When an exporter applies for a FTA Certificate of Origin, the exporter has certain obligations:",
      sentence2:
        "Obligation to receive a Declaration of Origin from the product supplier (manufacturer) to confirm the origin.",
      sentence3:
        "Obligation to maintain and provide evidence of the Certificate of Origin, supporting documents, and, when required, provide proof during post-verification.",
      sentence4:
        "Violation of the obligation to maintain documents or the inability to prove the origin as per the relevant laws may result in penalties and the duty to reimburse the importer.",
      sentence5:
        "If it is difficult for the exporter to fulfill these obligations, the FTA Certificate of Origin should not be applied.",
      sentence6:
        "If you agree to the Certificate of Origin, please follow the steps below:",
      sentence7:
        "Mark a 'V' in the agreement box and ensure that the FTA declaration is filled out on the invoice.",
      sentence8:
        "Print the invoice and manually write the name and signature of the exporter (writer) at the bottom of the FTA declaration.",
    },
    sequenceContent: {
      one: "① 동의 체크",
      two: "② FTA 협정문구 등록",
      three: "③ 운송장 번호 등록",
      four: "④ 협정 FTA Print 또는 excel 클릭",
    },
  },
};
