export default {
  ko: {
    FAV_MENU: "즐겨찾기",

    PURCHASE: "사입관리",
    PURCHASE_TAKE_IN_CODE: "상품관리",
    PURCHASE_WAYBILL_REGISTRATION: "운송장등록",
    PURCHASE_SHIPPING_LIST: "발송목록",
    PURCHASE_INVENTORY: "재고목록",

    INSPECTION: "검품",
    INSPECTION_LIST: "검품목록",
    INSPECTION_INVENTORY: "재고목록",

    WAYBILL: "운송장등록",
    WAYBILL_EXCEL_REGISTRATION: "엑셀운송장등록",
    WAYBILL_HANDWRITTEN_REGISTRATION: "수기등록",
    WAYBILL_PRINT: "운송장출력",
    WAYBILL_OUTGOING_CONFIRMATION: "출고확정",
    WAYBILL_TOTAL_TAXATION_CHECK: "합산과세체크",

    SHIPPING: "발송내역",
    SHIPPING_LIST: "발송목록",
    SHIPPING_DAILY_SHIPMENT_STATUS: "일별발송현황",
    SHIPPING_DELETED_ITEMS: "삭제목록",
    SHIPPING_COMMERCIAL: "Commercial",

    DELIVERY: "배송현황",
    DELIVERY_STATUS: "배송현황",
    DELIVERY_DUTY_SURTAX: "관부가세",
    DELIVERY_UNCUSTOMS: "미통관현황",
    DELIVERY_UNDELIVERED: "미배송현황",
    DELIVERY_DELAY: "목록통관 통관지연건",

    RETURN: "반품관리",
    RETURN_MANAGEMENT: "반품관리",
    RETURN_RECEIVING: "입고현황",
    RETURN_UNRECEIVING: "미입고현황",
    RETURN_SHIPPING: "출고현황",

    FTA: "FTA",
    FTA_FTA: "FTA",

    MY_PAGE: "My Page",
    MY_PAGE_MY_INFO: "My Info.",
    MY_PAGE_EXCEL_FIELD: "엑셀필드설정",
    MY_PAGE_INVOICE: "Invoice조회",

    BOARD: "게시판",
    BOARD_QNA: "QnA",
    BOARD_NOTICE: "공지사항",
  },
  en: {},
  zhHans: {},
};
