import type { SelectItem } from "@/definitions/types";
import i18n from "@/plugins/vue-i18n";

export const MenuType = {
  GROUP: "G",
  // REGISTER: "R",
  PAGE: "P",
  // INQUIRY: "I",
  // HELP: "H",
  NEW_TAB: "NEW_TAB",
};
export type MenuTypesString = (typeof MenuType)[keyof typeof MenuType];

export const BooleanType = {
  TRUE: "Y",
  FALSE: "N",
} as const;
export type BooleanTypesString = (typeof BooleanType)[keyof typeof BooleanType];
export const BOOLEAN_TYPE: SelectItem[] = [
  { value: BooleanType.TRUE, title: "예" },
  { value: BooleanType.FALSE, title: "아니요" },
];
export const LangType = {
  KR: "ko",
  EN: "en",
  CN: "zh-cht",
};

export type LangTypesString = (typeof LangType)[keyof typeof LangType];
export const LANG_TYPES: SelectItem[] = [
  {
    title: i18n.global.t("common.locale.ko"),
    value: LangType.KR,
    useYn: BooleanType.TRUE,
  },
  {
    title: i18n.global.t("common.locale.en"),
    value: LangType.EN,
    useYn: BooleanType.TRUE,
  },
  {
    title: i18n.global.t("common.locale.zh-cht"),
    value: LangType.CN,
    useYn: BooleanType.TRUE,
  },
];

export const FileAccessType = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};
export type FileAccessTypesString =
  (typeof FileAccessType)[keyof typeof FileAccessType];

export const CrudFlagType = {
  I: "I",
  U: "U",
  D: "D",
};

export type CrudFlagTypesString =
  (typeof CrudFlagType)[keyof typeof CrudFlagType];
