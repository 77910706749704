<template>
  <div class="crt-upd-info">
    <span v-if="createdDateTimeString">
      {{ createdByName ?? "-" }} / {{ createdDateTimeString }}
      {{ t("common.created") }}
    </span>
    <span v-if="updatedDateTimeString">
      <v-divider
        vertical
        color="black"
        style="margin-right: 4px; height: 13px"
      />
      {{ updatedByName ?? "-" }} / {{ updatedDateTimeString }}
      {{ t("common.updated") }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { formatDatetime } from "@/utils/formatter";
import { computed } from "vue";
import type { DateTime } from "@/definitions/types";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  createdByName?: string;
  createdDateTime?: DateTime;
  updatedByName?: string;
  updatedDateTime?: DateTime;
}>();

const createdDateTimeString = computed((): string => {
  if (!props.createdDateTime) {
    return "";
  }
  return formatDatetime(props.createdDateTime);
});

const updatedDateTimeString = computed((): string => {
  if (!props.updatedDateTime) {
    return "";
  }
  return formatDatetime(props.updatedDateTime);
});
</script>
<style scoped lang="scss">
.crt-upd-info {
  align-self: center;
  text-align: start;
  width: 100%;
  z-index: -1;
  > span {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: left;
    padding-right: 8px;
  }
}
</style>
