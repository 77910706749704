export default {
  ko: {
    osSeller: "해외판매자명",
    osSellerNo: "해외판매자등록부호",
    salesBroker: "판매중개업자명",
    salesBrokerNo: "판매중개업자등록부호",
    prchsAgent: "구매(배송)대행업체명",
    prchsAgentNo: "구매(배송)대행업체등록부호",
    taxDutyAdvancePayment: "관부과세 대납여부",
    getBy: "용도구분",
    boxCnt: "포장개수(박스)",
    wt: "WT",
    totalValue: "Total Value",
    userWta: "실무게",
    userWtc: "부피무게",
    orderDate: "등록(주문)일자",
    hapsanType: "조회구분",
    hawbDetailDialog: "운송장 상세",
    shipper: "발송업체",
    shipperName: "판매처 상호",
    shipperTel: "판매처 TEL",
    shipperAddr: "판매처 해외주소",
    customsSpcName: "특별통관 업체명",
    customsSpcNo: "특별통관 부호",
    outgoingConfirm: "선택한 운송장 출고확정처리 하시겠습니까?",
    cancelOutgoingConfirm: "선택한 운송장 출고확정 취소 하시겠습니까?",
    saveButton: "배송자료등록",
    excelRegistration: {
      pageHint1:
        "업로드하는 양식이 없을 경우 Setting 메뉴의 엑셀필드설정에서 추가해주세요.",
      pageHint1ButtonTitle: "Setting 바로가기",
      pageHint2: "[중요]세관적하 신고시 주의사항",
      pageHint2ButtonTitle: "바로가기",
      saveButton: "배송자료 선택등록",
      saveAllButton: "배송자료 전체등록",
      excelHeader: {
        orderDate: "등록(주문일자)",
        orderNo: "고객주문번호",
        hawbNo: "운송장번호",
        cneeName: "수하인",
        cneeTel: "수하인TEL",
        cneeAddr1: "수하인주소",
        buySite: "수하인 구매사이트",
        realBuySite: "Shipper구매사이트",
        salesBroker: "판매중개업자명",
        salesBrokerNo: "판매중개업자등록부호",
        prchsAgent: "구매대행업체명",
        prchsAgentNo: "구매대행업체등록부호",
        osSeller: "해외판매자명",
        osSellerNo: "해외판매자등록부호",
        taxDutyAdvancePayment: "관부과세대납여부",
        getBy: "용도구분",
        mallType: "상거래유형",
        boxCnt: "포장개수(박스)",
        totalValue: "Total Value",
        hsCode: "HS Code",
        itemBrand: "상품브랜드",
        itemDetail: "상품명",
        itemValue: "Item Value",
      },
    },
    print: {
      printA4Button: "운송장출력(A4)",
      printLabelButton: "운송장출력(LABEL)",
      printLabelSideButton: "운송장출력(LABEL)-SIDE",
      printLabelSide2Button: "운송장출력(LABEL)-SIDE2",
      printCJButton: "CJ운송장출력(LABEL)",
      printZPLButton: "ZPL",
      outgoingButton: "출고확정",
      excelOutgoingButton: "출고확정-엑셀",
      addButton: "운송장등록",
      storefarmButton: "Storefarm주문양식",
      removeConfirm: "{value}건의 운송장 삭제 하시겠습니까?",
      excelRegistrationTitle: "출고확정 - Excel 대량등록",
      excelRegistrationHint: "A열: 운송장번호",
      infoExtFormTitle: "기타정보",
      excelValidation:
        "엑셀에 유효한 운송장번호가 없습니다. 엑셀파일을 확인해주세요.",
    },
    outgoing: {
      cancelButton: "출고확정 취소",
    },
  },
  en: {},
  zhHans: {},
};
