<template>
  <Component
    :is="popup ? VDialog : VBottomSheet"
    :model-value="true"
    :width="popup ? '40%' : undefined"
    @keydown.esc="emits('click:cancel', modelValue)"
    @update:model-value="(val) => !val && emits('click:cancel', modelValue)"
  >
    <v-card>
      <v-card-title
        :class="{
          'pl-8': popup,
          'pt-6': popup,
        }"
      >
        {{
          title ?? t("variable.detail", { text: t("board.notice.pageTitle") })
        }}
      </v-card-title>
      <v-card-text>
        <v-form ref="refForm">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :model-value="modelValue.subject"
                :label="t('common.title')"
                variant="plain"
                disabled
              />
            </v-col>
            <v-col v-if="modelValue.attachments?.[0]" cols="12">
              <v-btn
                color="primary"
                variant="plain"
                size="small"
                @click="emits('click:download-file', modelValue)"
              >
                <v-icon>mdi-file-download</v-icon>
                {{ t("common.downloadFile") }}
                {{
                  modelValue.attachments[0].fileTitle ??
                  modelValue.attachments[0].fileName
                }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <TextEditor
                :model-value="modelValue.noticeComment"
                required
                disabled
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions
        :class="{
          'pl-8': popup,
        }"
      >
        <CreatedUpdatedBar
          :created-by-name="modelValue.creator?.userName"
          :created-date-time="modelValue.crtDt"
          :updated-by-name="modelValue.updater?.userName"
          :updated-date-time="modelValue.updDt"
        />
        <v-btn
          color="primary"
          width="100"
          variant="plain"
          density="default"
          size="x-large"
          height="40"
          @click="emits('click:cancel', modelValue)"
          >{{ t("button.close") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </Component>
</template>

<script setup lang="ts">
import type { Notice } from "@/views/board/notice/models";
import TextEditor from "@/views/components/texteditor/TextEditor.vue";
import CreatedUpdatedBar from "@/views/components/history/CreatedUpdatedBar.vue";
import { useI18n } from "vue-i18n";
import { VDialog } from "vuetify/components/VDialog";
import { VBottomSheet } from "vuetify/components/VBottomSheet";

const { t } = useI18n();

defineProps<{
  modelValue: Notice;
  title?: string;
  popup?: boolean;
}>();

const emits = defineEmits<{
  (e: "click:cancel", v: Notice);
  (e: "click:download-file", v: Notice);
}>();
</script>
