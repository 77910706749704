export default {
  ko: {
    blStatus: "진행상황",
    hawbNo: "원운송장번호",
    searchHawbNo: "운송장번호 찾기",
    shipperName: "발송인명",
    returnType: "반송유형",
    crtDt: "등록일",
    collectCourier: "수거택배",
    shippingInformation: "발송정보",
    qna: "문의사항",
    question: "문의",
    courier: "택배사",
    localTrkNo: "택배번호",
    localTrkDate: "접수일",
    customerMessage: "고객메시지",
    adminMessage: "관리자메시지",

    btn: {
      create: "접수하기",
      deliveryStatus: "수거배송조회",
      question: "문의하기",
    },

    management: {},
    ReturnEditDialog: {
      title: "반품 상세 정보",
    },
    ReturnQnaDialog: {
      title: "문의하기",
    },
    ReturnAddDialog: {
      title: "반품 접수하기",
    },
    ReturnDeliveryDialog: {
      title: "수거 배송조회",
    },
    ReturnUsageGuide: "반품 사용 안내서",
    ReturnUsageDetail:
      "ACI는 이용자의 개인정보를 사전 동의 없이 반품회수대행 업무 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.\n" +
      "\n" +
      "다만, 아래의 경우에는 예외로 합니다.\n" +
      "- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우\n" +
      "- 반품회수대행 서비스를 위한 거래 당사자간(ACI의 서비스 위탁 업체) 원활한 의사소통 및 배송, 상담 등 거래이행을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게 제공에 동의한 경우",
    Agree: "동의합니다",
    returnManagement: "반품관리",
    returnInfo: "반품정보",
    returnContactInfo: "반품 담당자 정보",
    returnShippingInfo: "반품 발송 정보",
    foreignDestinationInfo: "해외 도착지 정보",
    deliveryCompanyInfo: "배송사 정보",
    customerInfo: "고객 정보",
    placeboReturnInfo: "위약반송 정보",
    returnReason: "반송사유서",
    bankAccountCopy: "통장사본",
    returnCapture: "반품접수 캡쳐본",
    returnMessageCapture: "반품관련메시지 캡쳐본",
    returnCommercial: "반품커머셜",
    selectReturnType: "반품유형 선택",
    basicReturnService: "기본 반품서비스",
    premiumReturnService: "프리미엄 반품서비스",
    aciReturnAddress: "ACI 반품주소",
    showUnitPrice: "단가보기",
    requestReturn: "반품신청",
    cancelReturn: "반품취소",
    progress: "진행상황",
    originTrackingNumber: "원운송장번호",
    senderName: "발송인명",
    registrationDate: "등록일",
    pickUpCourier: "수거 택배",
    departureDate: "출발일",
    exportedBlNo: "해외출고 BL No",
    reception: "접수하기",
    accepting: "접수중",
    onCollection: "수거중",
    wearing: "입고",
    release: "출고",
    completionOfReturn: "반품완료",
    searchResult: "검색결과가 존재 하지 않습니다.",
    download: "다운로드",

    // 추가된 항목
    attnName: "담당자 이름",
    attnTel: "담당자 연락처",
    attnEmail: "담당자 이메일",
    shipperAddr: "발송인 주소",
    shipperTel: "발송인 연락처",
    cneeName: "수취인명",
    cneeAddr: "수취인 주소",
    cneeTel: "수취인 연락처",
    jmNum: "주민등록번호",
    returnDvlType: "반송 구분",
    returnDiv: "재패킹 구분",
    refundsYn: "수출면장 발급여부",
    OrderItemInfo: "상품정보",
    OrderItemName: "상품명",
    OrderItemPCS: "상품수량",
    PlaceboReturnInformation: "위약반송 정보",
    RequestReturn: "반품신청",
    CancelReturn: "반품취소",
    confirmCancel: "정말로 취소하시겠습니까?",
    confirmDelete: "정말로 삭제하시겠습니까?",
    defaultValuesLoaded: "기본값이 로드되었습니다.",
    noDefaultValuesFound: "기본값을 찾을 수 없습니다.",
    agreementRequired: "반품 사용 안내 동의 후 진행할 수 있습니다.",
    vacantItemList: "조회된 상품정보가 없습니다.",
    hawbNoSearchNotice:
      "운송장번호 찾기를 통해 반품할 운송장번호를 검색해주세요.",
    boxNotice: "박스: 비용발생",
    polybagNotice: "폴리백: 무상제공",
    fragileNotice: "파손시 보상불가",
    warningNoticeForReturn1: "*해외로 발송되는 반품 건만 입력해주세요",
    warningNoticeForReturn2: "*국내 회수 및 반품은 입력하지 말아주세요",
    warningNoticeForRefund: "*관부가세 환급시 반드시 작성해주세요",
    isNotAvailableEdit: "이미 접수처리되어 수정할 수 없습니다.",
    receiving: {
      imgUrl: "이미지",
      whCnt: "수량",
      localTrkCom: "수거 택배",
      returnHawbNo: "송장번호",
      wDate: "접수일",
      whInDate: "입고일",
      shipperInfo: "회수지 정보",
      shipperName: "발송인",
      shipperTel: "연락처",
      shipperAddr: "주소",
      adminCsMemo: "관리자메시지",
      cusCsMemo: "고객메시지",
      detail: "상세내용",
      contact: "문의하기",
      detailPopup: {
        popupTitle: "입고 상세",
        returnInfo: "반송 도착지 정보",
        cneeName: "회사명",
        cneeTel: "Tel",
        cneeAddr: "주소",
        itemDetail: "상품명",
        returnCnt: "입고수량",
        adminCsMemo: "ACI 메시지",
        userId: "등록 ID",
        csComment: "고객 메시지",
        itemImgUrl: "상품 이미지",
      },
    },
    shipping: {
      sendInfo: "출고정보",
      hawbNo: "해외출고 BL NO",
      trkCom: "택배사",
      hawbDate: "출고일자",
      cneeInfo: "수취인 정보",
      sendCnt: "출고수량",
      cneeName: "업체명",
      cneeTel: "연락처",
      cneeAddr: "주소",
      detail: "상세내용",
      delivery: "배송조회",
      packageNo: "packageNo",
      detailPopup: {
        popupTitle: "출고현황 상세",
        returnHawbNo: "원송장번호",
      },
    },
  },
  en: {},
  zhHans: {},
};
