export default {
  ko: {
    signIn: "로그인",
    signInId: "로그인 ID",
    findPassword: "비밀번호 찾기",
    failSignIn:
      "로그인에 실패하였습니다. 입력정보를 다시 확인한 후 시도해주세요.",
    successResetPassword: "비밀번호 재설정이 완료되었습니다.",
    failResetPassword:
      "비밀번호 재설정이 실패하였습니다. 처음부터 다시 시도해주세요.",
    successResetPasswordRequest: "비밀번호 재설정 메일을 발송하였습니다.",
    failResetPasswordRequest:
      "전송에 실패하였습니다. 입력정보를 다시 확인한 후 시도해주세요.",
    noneToken: "비밀번호 재설정 토큰이 없습니다.",
  },
  en: {},
  zhHans: {},
};
