export default {
  ko: {
    hsCode: "HS CODE",
    link: "https://unipass.customs.go.kr/clip/index.do",
    linkHint:
      "아래 표에 해당되는 상품이 없는 경우 여기에서 검색해 주시기 바랍니다.",
    item: "한글품목",
    productName: "제품명",
    hsCodeUnit6: "HS CODE 6단위",
    hsCode33: {
      item: "조제향료와 화장품류 또는 화장용품류",
      code330300: {
        item: "향수",
      },
      code330410: {
        item: "립스틱",
      },
      code330420: {
        item: "눈화장용 제품",
      },
      code330430: {
        item: "메니큐어",
      },
      code330499: {
        item: "기초화장품, 메이크업 화장품",
      },
      code330710: {
        item: "쉐이빙폼",
      },
      code330790: {
        item: "마스크팩",
      },
    },
    hsCode34: {
      item: "비누·유기계면활성제·조제세제·조제윤활제·인조왁스·조제왁스·광택 또는 연마조제품·양초와 이와 유사한 물품·조형용 페이스트·치과용왁스와 플라스터를 기제로 한 치과용 조제품",
      code340120: {
        item: "비누",
      },
      code340250: {
        item: "세제",
      },
      code340399: {
        item: "조제윤활유",
      },
      code340420: {
        item: "왁스",
      },
      code340600: {
        item: "양초",
      },
      code340700: {
        item: "조형용,치과용 조제품",
      },
    },
    hsCode37: {
      item: "사진용 또는 영화용의 재료",
      code370390: {
        item: "사진인화지",
      },
      code370500: {
        item: "현상한 사진필름",
      },
      code370690: {
        item: "현상한 영화필름",
      },
      code370790: {
        item: "사진용의 화학조제품",
      },
    },
    hsCode38: {
      item: "각종 화학공업 생산품",
      code380891: {
        item: "살충제",
      },
      code381239: {
        item: "고무,플락스틱용 화학조제품",
      },
      code382499: {
        item: "기타 화확공업생산품",
      },
    },
    hsCode39: {
      item: "플라스틱과 그 제품",
      code391729: {
        item: "관,파이프,호스",
      },
      code391890: {
        item: "바닥깔개",
      },
      code391990: {
        item: "접착성 쉬트.테이프",
      },
      code392190: {
        item: "기타 판,쉬트",
      },
      code392210: {
        item: "목욕통.샤워통.설거지통",
      },
      code392310: {
        item: "상자·케이스·바구니와 이와 유사한 물품",
      },
      code392329: {
        item: "포장용기,마개",
      },
      code392490: {
        item: "식탁용품과 주방용품",
      },
      code392590: {
        item: "건축용품",
      },
      code392610: {
        item: "사무용품이나 학용품",
      },
      code392690: {
        item: "기타제품",
      },
    },
    hsCode40: {
      item: "고무와 그 제품",
      code400819: {
        item: "쉬트,봉",
      },
      code400941: {
        item: "관,호스",
      },
      code401110: {
        item: "공기타이어(승용차)",
      },
      code401310: {
        item: "이너튜브-승용차",
      },
      code401410: {
        item: "콘돔",
      },
      code401490: {
        item: "젖꼭지",
      },
      code401590: {
        item: "고무의류",
      },
      code401699: {
        item: "기타 고무제품",
      },
    },
    hsCode42: {
      item: "가죽제품·마구·여행용구·핸드백 및 이와 유사한 용기와 동물의 거트(누에의 거트를 제외한다)의 제품",
      code420222: {
        item: "핸드백",
      },
      code420232: {
        item: "지갑.카드지갑",
      },
      code420310: {
        item: "가죽의류-의류",
      },
      code420321: {
        item: "운동용으로 특수 디자인한 장갑",
      },
      code420329: {
        item: "기타 작업용 장갑",
      },
      code420330: {
        item: "벨트와 띠",
      },
      code420500: {
        item: "기타 가죽제품",
      },
      code420600: {
        item: "거트제품",
      },
    },
    hsCode44: {
      item: "목재와 그 제품 및 목탄",
      code441510: {
        item: "케이스.상자",
      },
      code441600: {
        item: "목제의 통,배럴",
      },
      code441700: {
        item: "목제의 공구",
      },
      code441899: {
        item: "건축용 목제품",
      },
      code441990: {
        item: "식탁용품과 주방용품",
      },
      code442090: {
        item: "장식품",
      },
      code442199: {
        item: "이쑤시개",
      },
    },
    hsCode46: {
      item: "짚·에스파르토 또는 기타 조물재료의제품과 농세공물 및 지조세공물",
      code460199: {
        item: "시트 모양의 조물제품",
      },
      code460219: {
        item: "바구니 세공물(핸드백.쇼핑백 유사물품)",
      },
      code460290: {
        item: "그 밖의 조물제품",
      },
    },
    hsCode48: {
      item: "지와 판지, 제지용 펄프, 지 또는 판지의 제품",
      code481149: {
        item: "도포,피복,착색한 지",
      },
      code481710: {
        item: "봉투,엽서,필기첩-봉투",
      },
      code481810: {
        item: "화장지,위생용품",
      },
      code481910: {
        item: "상자·포장용기",
      },
      code482190: {
        item: "지제의 레이블",
      },
      code482390: {
        item: "기타제품",
      },
    },
    hsCode49: {
      item: "인쇄서적·신문·회화 및 기타의 인쇄물, 수제문서·타이프문서 및 도면",
      code490199: {
        item: "인쇄서적",
      },
    },
    hsCode52: {
      item: "면",
      code521215: {
        item: "기타 면직물",
      },
    },
    hsCode54: {
      item: "인조필라멘트",
      code540120: {
        item: "재봉사",
      },
    },
    hsCode55: {
      item: "인조스테이플섬유",
      code550820: {
        item: "재봉사",
      },
      code551599: {
        item: "기타합성스테이플섬유직물",
      },
    },
    hsCode57: {
      item: "양탄자류와 기타 방직용 섬유제의 바닥깔개",
      code570500: {
        item: "가정용 실내매트",
      },
    },
    hsCode60: {
      item: "메리야스 편물과 뜨게질 편물",
      code600191: {
        item: "의류제작용",
      },
    },
    hsCode61: {
      item: "기타 편물제 의류(메리야스 편물 또는 뜨게질 편물의 것에 한한다)",
      code611490: {
        item: "기타 편물제 의류",
      },
    },
    hsCode62: {
      item: "의류와 그 부속품(메리야스 편물 또는 뜨게질 편물의 것을 제외한다)",
      code621149: {
        item: "비편물제 기타 의류",
      },
      code621490: {
        item: "비편물제 기타 스카프",
      },
    },
    hsCode63: {
      item: "제품으로 된 방직용 섬유의 기타 물품, 세트, 사용하던 의류·사용하던 방직용 섬유제품 및 넝마",
      code630190: {
        item: "모포와 러그",
      },
      code630499: {
        item: "기타 실내용품",
      },
      code630629: {
        item: "캠프용품",
      },
      code630790: {
        item: "안면마스크",
      },
      code630800: {
        item: "자수용 세트",
      },
      code630900: {
        item: "구제의류",
      },
      code631090: {
        item: "끈·배의 밧줄·로프",
      },
    },
    hsCode64: {
      item: "신발류·각반 및 이와 유사한 것과 이들의 부분품",
      code640590: {
        item: "신발",
      },
      code640299: {
        item: "샌들",
      },
    },
    hsCode65: {
      item: "모자류와 그 부분품",
      code650400: {
        item: "라피아모자",
      },
      code650500: {
        item: "기타 직물제 모자",
      },
    },
    hsCode68: {
      item: "석·플라스터·시멘트·석면·운모 또는 이와 유사한 재료의 제품",
      code681599: {
        item: "기타 광물성 제품",
      },
    },
    hsCode69: {
      item: "도자제품",
      code691200: {
        item: "도자제의 식탁·주방용품",
      },
      code691390: {
        item: "도자제의 소상·장식용제품",
      },
      code691490: {
        item: "도자제의 기타 제품",
      },
    },
    hsCode70: {
      item: "유리와 유리제품",
      code701090: {
        item: "유리제의 용기",
      },
      code701310: {
        item: "식탁,주방,사무용품",
      },
      code701510: {
        item: "시계,안경용 유리",
      },
    },
    hsCode71: {
      item: "천연 또는 양식진주·귀석 또는 반귀석·귀금속·귀금속을 입힌 금속 및 이들의 제품, 모조신변장식용품과 주화",
      code710110: {
        item: "천연진주",
      },
      code710239: {
        item: "다이아몬드",
      },
      code711419: {
        item: "금은세공품",
      },
      code711590: {
        item: "귀금속제 기타제품",
      },
      code711610: {
        item: "진주,귀석의 제품",
      },
      code711719: {
        item: "모조 신변장식용품",
      },
      code711890: {
        item: "주화",
      },
    },
    hsCode72: {
      item: "철강",
      code721899: {
        item: "스테인리스 일차제품",
      },
      code722490: {
        item: "그 밖의 합금강이나 그 밖의 일차제품",
      },
    },
    hsCode73: {
      item: "철강의 제품",
      code730799: {
        item: "철강 관연결구",
      },
      code731589: {
        item: "체인",
      },
      code731819: {
        item: "스크루·볼트·너트",
      },
      code731990: {
        item: "침·바늘·핀",
      },
      code732690: {
        item: "기타 철강제품",
      },
    },
    hsCode74: {
      item: "동과 그 제품",
      code741220: {
        item: "관.연결구",
      },
      code741300: {
        item: "연선·케이블",
      },
      code741810: {
        item: "식탁·주방·위생용품",
      },
      code741980: {
        item: "기타 구리제품",
      },
    },
    hsCode76: {
      item: "알루미늄과 그 제품",
      code760900: {
        item: "관.연결구",
      },
      code761510: {
        item: "식탁·주방·위생용품",
      },
      code761699: {
        item: "기타 알루미늄제품",
      },
    },
    hsCode81: {
      item: "기타 비금속, 서메트, 이들의 제품",
      code811299: {
        item: "기타 비금속",
      },
      code811300: {
        item: "서멧",
      },
    },
    hsCode82: {
      item: "비금속제의 공구·도구·칼붙이·스푼과 포크 및 이들의 부분품",
      code820190: {
        item: "농업·원예용의 공구",
      },
      code820559: {
        item: "기타 수공구",
      },
      code820890: {
        item: "기계용이나 기구용 칼과 절단용 칼날",
      },
      code821000: {
        item: "수동식 기계기구(분쇄기.커터.오프너.식품가공기기)",
      },
      code821191: {
        item: "식탁용 칼",
      },
      code821210: {
        item: "비전기식 면도기와 면도날",
      },
      code821490: {
        item: "칼붙이의 기타 제품",
      },
      code821599: {
        item: "주방·식탁용품(스푼.포크.각종집게)",
      },
    },
    hsCode83: {
      item: "비금속제의 각종 제품",
      code830110: {
        item: "자물쇠·열쇠",
      },
      code830300: {
        item: "금고",
      },
      code830400: {
        item: "서류정리함·카드인덱스함·사무실용이나 책상용 비품",
      },
      code830610: {
        item: "벨·징과 이와 유사한 것",
      },
      code830629: {
        item: "작은 조각상과 그 밖의 장식품",
      },
      code830630: {
        item: "사진틀·그림틀이나 이와 유사한 틀, 거울",
      },
      code831000: {
        item: "사인판·명판·표지판",
      },
    },

    hsCode84: {
      item: "원자로·보일러와 기계류 및 이들의 부분품",
      code841280: {
        item: "엔진과 모터",
      },
      code841460: {
        item: "팬, 레인지후드(선풍기, 송풍기, 환풍기, 레인지후드, 전기냉풍기)",
      },
      code841869: {
        item: "냉장고·냉동고와 그 밖의 냉장기구나 냉동기구",
      },
      code841989: {
        item: "가열·살균·건조기기",
      },
      code842310: {
        item: "체중기",
      },
      code843680: {
        item: "기타의 농업,원예용 기계",
      },
      code845019: {
        item: "세탁기",
      },
      code845129: {
        item: "건조기",
      },
      code845210: {
        item: "가정형 재봉기",
      },
      code847130: {
        item: "컴퓨터.노트북",
      },
      code847160: {
        item: "키보드.마우스.스캐너",
      },
      code847170: {
        item: "하드디스크(보조기억장치)",
      },
      code847180: {
        item: "사운드카드.비디오카드",
      },
      code847330: {
        item: "컴퓨터 부분품",
      },
      code847989: {
        item: "기타 가정용기계",
      },
      code848580: {
        item: "3D 프린터",
      },
      code848790: {
        item: "기계류의 부분품",
      },
    },
    hsCode85: {
      item: "전기기기와 그 부분품, 녹음기와 음성재생기·텔레비젼의 영상 및 음성의 기록기와 재생기 및 이들의 부분품과 부속품",
      code850440: {
        item: "인버터.배터리충전기.파워팩.어댑터",
      },
      code850680: {
        item: "일회용 건전지",
      },
      code850760: {
        item: "축전지",
      },
      code850860: {
        item: "진공청소기.로봇청소기",
      },
      code850940: {
        item: "믹서기.추출기",
      },
      code850980: {
        item: "가정용 전기기기-커피분쇄기",
      },
      code851010: {
        item: "전기면도기",
      },
      code851310: {
        item: "손전등",
      },
      code851631: {
        item: "헤어드라이어",
      },
      code851640: {
        item: "전기다리미",
      },
      code851660: {
        item: "전기오븐,밥솥",
      },
      code851671: {
        item: "커피머신",
      },
      code851672: {
        item: "토스터",
      },
      code851713: {
        item: "스마트폰",
      },
      code851762: {
        item: "블루투스 이어폰.무전기.라우터",
      },
      code851810: {
        item: "마이크로폰 세트",
      },
      code851830: {
        item: "블루투스 스피커",
      },
      code851840: {
        item: "앰프(가청주파증폭기)",
      },
      code851930: {
        item: "턴테이블",
      },
      code852351: {
        item: "USB.SD카드",
      },
      code852380: {
        item: "CD,DVD",
      },
      code852589: {
        item: "텔레비전 카메라·디지털 카메라·비디오카메라레코더",
      },
      code852610: {
        item: "네비게이션",
      },
      code852713: {
        item: "라디오",
      },
      code852869: {
        item: "프로젝터",
      },
      code852872: {
        item: "텔레비전",
      },
      code853952: {
        item: "엘이디램프",
      },
      code854370: {
        item: "기타 가정용 전기기기(오디오믹서.전자사전.저주파마사지기.전자담배)",
      },
      code854449: {
        item: "절연전선·케이블",
      },
    },
    hsCode87: {
      item: "철도 또는 궤도용 외의 차량 및 그 부분품과 부속품",
      code870899: {
        item: "자동차 부분품",
      },
      code871200: {
        item: "자전거",
      },
      code871499: {
        item: "자전가 부분품",
      },
      code871500: {
        item: "유모차",
      },
      code871680: {
        item: "손수레.썰매",
      },
    },
    hsCode90: {
      item: "광학기기·사진용기기·영화용기기·측정기기·검사기기",
      code900410: {
        item: "선글라스",
      },
      code900490: {
        item: "안경테",
      },
      code900510: {
        item: "쌍안경",
      },
      code900580: {
        item: "망원경",
      },
      code900640: {
        item: "즉석인화 사진기",
      },
      code900659: {
        item: "필름카메라",
      },
      code901180: {
        item: "현미경",
      },
      code901380: {
        item: "조준경.확대경",
      },
      code901510: {
        item: "거리측정기",
      },
      code901819: {
        item: "혈압 측정기기",
      },
      code901831: {
        item: "주사기",
      },
      code901890: {
        item: "임심진단기",
      },
      code901910: {
        item: "맛사지 기기",
      },
      code901920: {
        item: "호흡 치료기기",
      },
      code902519: {
        item: "체온계",
      },
    },
    hsCode91: {
      item: "시계와 그 부분품",
      code910219: {
        item: "휴대용시계",
      },
      code910529: {
        item: "벽시계",
      },
      code911180: {
        item: "시계 케이스",
      },
      code911390: {
        item: "휴대용 시곗줄·휴대용 시계밴드·휴대용 시계팔찌",
      },
      code911490: {
        item: "기타 시계의 부분품",
      },
    },
    hsCode92: {
      item: "악기 및 그 부분품과 부속품",
      code920190: {
        item: "피아노",
      },
      code920210: {
        item: "바이올린.첼로",
      },
      code920290: {
        item: "기타.하프",
      },
      code920510: {
        item: "트럼펫",
      },
      code920590: {
        item: "플루트.색소폰.리코더.하모니카",
      },
      code920600: {
        item: "북.심벌.캐스터네츠",
      },
      code920710: {
        item: "전자 피아노",
      },
      code920790: {
        item: "전자 기타",
      },
      code920999: {
        item: "악기의 부분품",
      },
    },
    hsCode94: {
      item: "의자·침대·매트리스·매트리스서포트·쿠션과 이와 유사한 물품, 다른 류에 분류되지 아니한 램프와 조명기구, 조명용 사인·조명용 네임플레이트와 이와 유사한 물품 및 조립식 건축물",
      code940180: {
        item: "의자.카시트",
      },
      code940350: {
        item: "침대",
      },
      code940389: {
        item: "기타 가구",
      },
      code940429: {
        item: "매트리스",
      },
      code940430: {
        item: "침낭",
      },
      code940440: {
        item: "이불·침대보·솜털이불·깃털이불",
      },
      code940490: {
        item: "전기이불, 전기요, 전기장판, 전기매트, 전기카펫, 전기온수매트",
      },
      code940511: {
        item: "천장용·벽 부착용 전기식 조명기구",
      },
      code940550: {
        item: "비전기식 램프와 조명기구",
      },
    },
    hsCode95: {
      item: "완구·유희용구·운동용구 및 이들의 부분품과 부속품",
      code950300: {
        item: "세발자전거·스쿠터·페달 자동차와 이와 유사한 바퀴가 달린 완구, 인형용 차, 인형과 그 밖의 완구, 축소 모형과 이와 유사한 오락용 모형 (작동하는 것인지에 상관없다), 각종 퍼즐",
      },
      code950450: {
        item: "비디오게임기",
      },
      code950590: {
        item: "오락용품(마술용품과 기술용품)",
      },
      code950611: {
        item: "스키",
      },
      code950612: {
        item: "수상스키",
      },
      code950631: {
        item: "골프채",
      },
      code950632: {
        item: "골프공",
      },
      code950640: {
        item: "탁구용구(라켓.공)",
      },
      code950659: {
        item: "테니스라켓.배드민턴라켓",
      },
      code950662: {
        item: "공기주입식 공(축구공.농구공.배구공)",
      },
      code950669: {
        item: "배드민턴 셔틀콕.야구공",
      },
      code950670: {
        item: "아이스 스케이트와 롤러스케이트",
      },
      code950691: {
        item: "일반적으로 육체적 운동·체조·육상 경기에 사용하는 물품",
      },
    },
    hsCode96: {
      item: "잡품",
      code960310: {
        item: "비와 브러시",
      },
      code960321: {
        item: "칫솔",
      },
      code960330: {
        item: "회화용 붓·필기용 붓과 이와 유사한 화장용 붓",
      },
      code960500: {
        item: "개인용의 여행세트",
      },
      code960622: {
        item: "담배파이프",
      },
      code960810: {
        item: "볼펜",
      },
      code960830: {
        item: "만년필",
      },
      code960910: {
        item: "연필.색연필.크레용",
      },
      code960990: {
        item: "파스텔.초크",
      },
      code961100: {
        item: "스탬프",
      },
      code961380: {
        item: "라이터",
      },
      code961400: {
        item: "담배파이프",
      },
      code961519: {
        item: "빗",
      },
      code961590: {
        item: "머리핀",
      },
      code961900: {
        item: "위생 타월·탐폰, 냅킨(기저귀)·냅킨라이너",
      },
      code962000: {
        item: "삼각대",
      },
    },
    hsCode97: {
      item: "예술품·수집품과 골동품",
      code970191: {
        item: "회화.데생.파스텔",
      },
      code970290: {
        item: "오리지널 동판화·목판화·석판화",
      },
      code970390: {
        item: "오리지널 조각과 조상",
      },
      code970400: {
        item: "우표·수입인지·우편엽서류",
      },
      code970510: {
        item: "수집품과 표본",
      },
      code970690: {
        item: "골동품(제작 후 100년을 초과한 것으로 한정한다)",
      },
    },
  },
  en: {},
  zhHans: {},
};
