export default {
  ko: {
    recoveryDate: "복구일자",
    recoveryDt: "복구일시",
    removeDate: "삭제일자",
    removeDt: "삭제일시",
    removeIp: "삭제 IP",
    recoverConfirm: "복구하시겠습니까?",
    userWta: "WTA",
    userWtc: "WTC",
    userWtaWtc: "User WTA/WTC",
    wtaWtc: "ACI WTA/WTC",
    filterDeptDt: "현지발송일자(Dept. Date)",
    whYn: "입고여부",
    taxDutyAdvancePayment: "관부가세대납여부",
    invoiceWt: "Invoice WT",
    excelItem: "엑셀다운로드-상품",
    excelDeptDt: "엑셀다운로드-현지발송일",
    excelBackup: "엑셀다운로드-백업",
    pod: "화물추적",
    bl: "BL",
    freight: "Freight",
    deptDt: "출발일자",
    arrvDt: "도착일자",
    mawbDesc: "MAWB No.",
    fltNo: "편명",
    blQty: "운송장 개수",
    wtaTot: "WT(A)",
    wtcTot: "WT(C)",
    invcTot: "Invoice WT",
    getBy: "용도구분",

    commercial: {
      commercial: "Commercial",
      commercialTakeIn: "Commercial - 사입",
      printBL: "Print BL",
    },

    podDialog: {
      title: "POD",
      title2: "Track Shipments Detailed Results",
      trackingNumber: "Tracking Number",
      maskedCneeName: "받는 사람",
      date: "Date",
      time: "Time",
      area: "Area",
      detail: "Detail",
      postShipping: ">> 우체국 배송조회",
      dateKor: "날짜",
      timeKor: "시간",
      position: "현재 위치",
      traceStatus: "처리현황",
      detailDescription: "상세설명(배달결과)",
      receiver: "수령인",
      message1: "*표기된 시간은 각 서비스 지역의 현지 시간입니다.",
    },
  },
  en: {},
  zhHans: {},
};
