<template>
  <v-footer
    id="footer"
    border
    height="calc(100vh-40px)"
    class="d-block text-center py-1"
    :style="{
      color: 'rgb(0,0,0, 0.5)',
      fontSize: '14px',
      lineHeight: '16px',
      borderLeft: 'none',
      borderBottom: 'none',
      maxHeight: '36px',
    }"
  >
    (주) 에이씨아이 월드와이드
    <v-divider
      vertical
      color="black"
      style="margin-left: 4px; margin-right: 4px; height: 16px"
    />
    서울특별시 강서구 남부순환로 19길 121 (외발산동 217-1)
    <v-divider
      vertical
      color="black"
      style="margin-left: 4px; margin-right: 4px; height: 16px"
    />
    사업자등록번호: 105-81-52457
    <br />
    COPYRIGHTS (C) ACI WORLDWIDE EXPRESS CORPORATION ALL RIGHTS RESERVED
  </v-footer>
</template>
<script setup lang="ts"></script>
