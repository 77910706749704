import { defineStore } from "pinia";
import type { Confirm, ConfirmResolveResponse } from "@/definitions/types";
import { v4 as uuidV4 } from "uuid";
import i18n from "@/plugins/vue-i18n";

export const useConfirmStore = defineStore("confirm", {
  state: () => {
    return {
      items: [] as Confirm[],
      formSaved: true,
    };
  },
  actions: {
    confirmCreate(): Promise<boolean> {
      return this.pushConfirm(
        i18n.global.t("confirm.save"),
        i18n.global.t("button.save"),
      );
    },
    confirmUpdate(): Promise<boolean> {
      return this.pushConfirm(
        i18n.global.t("confirm.edit"),
        i18n.global.t("button.edit"),
      );
    },
    confirmRemove(): Promise<boolean> {
      return this.pushConfirm(
        i18n.global.t("confirm.remove"),
        i18n.global.t("button.remove"),
      );
    },
    async pushConfirm(
      content: string,
      title = i18n.global.t("confirm.title"),
      width = "400px",
      confirmButtonTitle = i18n.global.t("button.ok"),
      cancelButtonTitle = i18n.global.t("button.cancel"),
    ): Promise<boolean> {
      return new Promise<ConfirmResolveResponse>((resolve) => {
        this.$state.items = [
          ...this.$state.items,
          {
            uuid: uuidV4(),
            title: title,
            content: content,
            width: width,
            cancelButtonTitle: cancelButtonTitle,
            confirmButtonTitle: confirmButtonTitle,
            resolver: resolve,
          },
        ];
      }).then((response) => {
        this.$state.items = this.$state.items.filter(
          (item) => response.uuid !== item.uuid,
        );
        return response.value;
      });
    },
  },
});
