import { createI18n } from "vue-i18n";
import en from "vuetify/lib/locale/en.mjs";
import zhHans from "vuetify/lib/locale/zh-Hans.mjs";
import ko from "vuetify/lib/locale/ko.mjs";
import menu_messages from "@/i18n/locales/translations/menu_messages";
import board_messages from "@/i18n/locales/translations/board_messages";
import signin_messages from "@/i18n/locales/translations/signin_messages";
import rules_messages from "@/i18n/locales/translations/rules_messages";
import common_messages from "@/i18n/locales/translations/common_messages";
import variable_messages from "@/i18n/locales/translations/variable_messages";
import button_messages from "@/i18n/locales/translations/button_messages";
import confirm_messages from "@/i18n/locales/translations/confirm_messages";
import mypage_messages from "@/i18n/locales/translations/mypage_messages";
import inspection_messages from "@/i18n/locales/translations/inspection_messages";
import waybill_messages from "@/i18n/locales/translations/waybill_messages";
import shipping_messages from "@/i18n/locales/translations/shipping_messages";
import hscode_messages from "@/i18n/locales/translations/hscode_messages";
import receiving_messages from "@/i18n/locales/translations/receiving_messages";
import purchase_messages from "@/i18n/locales/translations/purchase_messages";
import delivery_messages from "@/i18n/locales/translations/delivery_messages";
import fta_messages from "@/i18n/locales/translations/fta_messages";
import return_messages from "@/i18n/locales/translations/return_messages";

const messages = {
  en: {
    $vuetify: en,
    common: common_messages.en,
    button: button_messages.en,
    fta: fta_messages.en,
  },
  ko: {
    $vuetify: ko,
    common: common_messages.ko,
    variable: variable_messages.ko,
    button: button_messages.ko,
    confirm: confirm_messages.ko,
    rules: rules_messages.ko,
    menu: menu_messages.ko,
    board: board_messages.ko,
    signIn: signin_messages.ko,
    myPage: mypage_messages.ko,
    inspection: inspection_messages.ko,
    waybill: waybill_messages.ko,
    shipping: shipping_messages.ko,
    hsCode: hscode_messages.ko,
    receiving: receiving_messages.ko,
    purchase: purchase_messages.ko,
    delivery: delivery_messages.ko,
    fta: fta_messages.ko,
    return: return_messages.ko,
  },
  "zh-cht": {
    $vuetify: zhHans,
    common: common_messages.zhHans,
    button: button_messages.zhHans,
    fta: fta_messages.zhHans,
  },
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: localStorage.getItem("locale") ?? "ko",
  fallbackLocale: "en",
  globalInjection: false,
  messages,
});

export default i18n;
