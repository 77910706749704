export default {
  ko: {
    hawbDetailDialog: "운송장 상세",
    shipperName: "판매처 상호",
    shipperTel: "판매처 TEL",
    shipperAddr: "판매처 해외주소",
    customsSpcName: "특별통관 업체명",
    customsSpcNo: "특별통관 부호",
    salesBroker: "판매중개업체명(플랫폼)",
    salesBrokerNo: "판매중개업체등록부호",
    prchsAgent: "구매/배송대행업체명",
    prchsAgentNo: "구매/배송대행업체등록부호",
    osSeller: "해외판매자명(해외직접수입형)",
    osSellerNo: "해외판매자등록부호",
    userWta: "User실무게",
    userWtc: "User 부피무게",
    aciWta: "ACI 실무게",
    aciWtc: "ACI 부피무게",
    infoExt: {
      formTitle: "기타정보",
    },
  },
};
