export default {
  ko: {
    notice: {
      notice: "공지사항",
      pageTitle: "공지사항",
      noticeForAll: "전 지점 공지",
      noticeExpired: "팝업 마감",
    },
    qna: {
      qna: "Q & A",
      pageTitle: "Q & A",
      answer: "답변",
      qnaAnswer: "Q & A 답변",
      previousWriting: "작성글",
      failRemoveBecauseChild: "답변이 있는 Q & A는 삭제할 수 없습니다.",
    },
  },
  en: {},
  zhHans: {},
};
