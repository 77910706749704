export default {
  ko: {
    indexNo: "No.",
    user: "User",
    userId: "@:common.user ID",
    userName: "@:common.user{'명'}",
    loginId: "사용자 ID",
    cusName: "거래처명",
    status: "상태",
    tel: "전화번호",
    tel2: "연락처",
    email: "이메일",
    address: "주소",
    representativeName: "대표자명",
    crt: "등록",
    crtDate: "@:common.crt{'일'}",
    crtDt: "@:common.crt{'일시'}",
    crtUser: "@:common.crt{'자'}",
    upd: "수정",
    updDt: "@:common.upd{'일시'}",
    updUser: "@:common.upd{'자'}",
    created: "@:common.crt{'됨'}",
    updated: "@:common.upd{'됨'}",
    work: "작업",
    workDt: "@:common.work{'일시'}",
    workUser: "@:common.work{'자'}",
    memo: "메모",
    memoInfo: "메모 정보",
    use: "사용",
    useYn: "사용여부",
    takbae: "택배",
    password: "비밀번호",
    language: "언어",
    station: "스테이션",
    remark: "비고",
    popup: "팝업",
    attachmentFile: "첨부 파일",
    download: "다운로드",
    downloadFile: "파일 @:common.download",
    title: "제목",
    content: "내용",
    manager: "담당자",
    managerName: "담당자명",
    orderDate: "주문일자",
    orderNo: "주문 번호",
    hawbNo: "운송장 번호",
    comment: "Comment",
    dateRange: "날짜 기간",
    requiredItem: "필수 항목",
    optionItem: "선택 항목",
    value: "Value",
    value2: "값",
    color: "컬러",
    size: "사이즈",
    qty: "Qty.",
    volume: "Volume",
    searchPostCode: "우편번호 검색",
    type1: "구분",
    type2: "종류",
    errorMessage: "에러 메시지",
    warningMessage: "경고 메시지",
    total: "Total",
    error: "Error",
    reqComment: "요청사항",
    reqDt: "요청일시",
    reqDate: "요청일자",
    reqStatus: "요청 상태",
    history: "변경 이력",
    totalSearch: "통합 검색",
    totalValue: "Total Value",
    noneImage: "이미지가 없습니다.",
    noneUrl: "연결할 페이지가 존재하지 않습니다",
    successLoadOldData: "기존 데이터를 성공적으로 불러왔습니다.",
    all: "전체",
    print: "Print",
    request: "요청",
    errorMessages: "Error Message",
    downloadFail: "다운로드에 실패하였습니다.",
    search: "검색",

    validation: {
      moveConfirm: "저장하지 않는 값은 사라집니다. 이 페이지를 벗어나겠습니까?",
      excelRequired: "필수입력항목 누락",
      excelInvalidDate: "날짜 형식 오류",
      excelInvalidData: "Invalid Data 오류",
      excelInvalidCustomNo: "개인통관부호 오류",
      excelInvalidCneeAddr1: "수하인 주소 오류",
      excelInvalidItemDetail: "상품명 오류",
    },

    result: {
      remove: "@:button:remove{'성공'}",
      failure: "요청에 실패하였습니다.",
      serviceUnavailable:
        "서비스를 사용할 수 없습니다. 관리자에게 연락하거나 나중에 시도하십시오.",
      noData: "No data returned from server",
      noParams: "처리 가능한 데이터 없음",
    },

    locale: {
      en: "영어",
      ko: "한국어",
      "zh-cht": "중국어",
    },
    suffix: {
      day: "일",
      number: "개",
      lb: "LB",
      inch: "Inch",
      cm: "cm",
    },
    filter: {
      conditionType: "검색 조건",
    },
  },
  en: {
    locale: {
      en: "English",
      ko: "Korean",
      "zh-cht": "Chinese",
    },

    result: {
      remove: "@:button:remove{'되었습니다.'}",
      failure: "요청에 실패하였습니다.",
      serviceUnavailable:
        "Service Unavailable. Please contact administrator or try later.",
      noData: "No data returned from server",
    },
  },
  zhHans: {
    locale: {
      en: "英文",
      ko: "韩国人",
      "zh-cht": "中文",
    },
  },
};
